// @flow
import * as React from 'react';
import dynamic from 'next/dynamic';
import gql from 'graphql-tag';
import MobileNavigationMain from './MobileNavigationMain';
import Query from '../ApolloBoundary/Query';
import { useUser, } from '../User/UserDispenser';
import togglePageScroll from '../../utils/togglePageScroll';
import getTransitionEnd from '../../utils/getTransitionEnd';
import type { MenuItemType, MainMenuType, } from './MenuItemType';
import WebViewExclude from '../WebViewExclude/WebViewExclude';
import usePrint from '../../hooks/Page/usePrint';

const DefaultSearch = dynamic(import('../Masthead/MastheadSearch/MastheadSearch'), { loading: () => null, });
const noop = () => { };

type MobileNavigationProps = {
  contentId: ?string,
  menuItems: { items: MenuItemType[], },
  Search: ?React.ElementType,
  pageTypeOverwrite: ?string,
  hideSearch: boolean,
}

type toggleMenuArgType = { onClose?: () => void, onOpen?: () => void, }

const GET_MOBILE_NAV_DATA = gql`
  query GetMobileNavData {
    pageType @client
    canonicalUrl @client
    title @client
  }
`;

MobileNavigation.defaultProps = {
  contentId: null,
  menuItems: null,
  Search: null,
  pageTypeOverwrite: null,
  hideSearch: false,
};
export default function MobileNavigation({
  contentId,
  menuItems,
  Search,
  pageTypeOverwrite,
  hideSearch,
}: MobileNavigationProps): React.Node {
  const { user, isLoggedIn, } = useUser();
  const [
    { open: menuIsOpen, closing: menuIsClosing, },
    setMenuState,
  ] = React.useState({ open: false, closing: false, });
  const menuRef = React.useRef(null);
  const mainNavRef = React.useRef(null);
  const sectionNavRef = React.useRef(null);
  const onCloseRef = React.useRef(null);
  const { isPrint, } = usePrint();

  const toggleScroll = React.useCallback((lockScroll, allowStyleChange = true) => {
    togglePageScroll(lockScroll, { targetElement: mainNavRef.current, allowStyleChange, });
    togglePageScroll(lockScroll, { targetElement: sectionNavRef.current, allowStyleChange, });
  }, []);

  const onSearchToggle = React.useCallback(searchDialogOpen => {
    if (!searchDialogOpen && (menuIsClosing || !menuIsOpen)) return;
    toggleScroll(!searchDialogOpen, !searchDialogOpen);
  }, [ menuIsClosing, menuIsOpen, toggleScroll, ]);

  const closeOnTransitionEnd = React.useCallback(onClose => {
    // In certain edge cases (such as rapid succesive menu toggles), the transition on the menu
    // element will not have a chance to occur, and thus the 'transitionEnd' event will never fire.
    // The timeout is set here as a backup for these cases.
    const menuEl = menuRef.current;
    let timeoutId;
    const closeMenu = () => {
      onCloseRef.current && onCloseRef.current();
      onClose && onClose();
      setMenuState({ open: false, closing: false, });
      clearTimeout(timeoutId);
      menuEl && menuEl.removeEventListener(getTransitionEnd(menuEl), closeMenu);
    };
    if (!menuEl) closeMenu();
    else {
      timeoutId = setTimeout(closeMenu, 800);
      menuEl.addEventListener(getTransitionEnd(menuEl), closeMenu);
    }
  }, []);

  const toggleMenu = React.useCallback(({
    onClose,
    onOpen,
  }: toggleMenuArgType = {}) => {
    setMenuState(({ open, closing, }) => {
      if (open && !closing) {
        closeOnTransitionEnd(onClose);
        toggleScroll(false);
        return { open, closing: true, };
      }
      if (!open) {
        toggleScroll(true);
        onOpen && onOpen();
        return { open: true, closing: false, };
      }
      return { open, closing, };
    });
  }, [ closeOnTransitionEnd, toggleScroll, ]);

  const SearchComponent = Search || DefaultSearch;

  if (isPrint) {
    return null;
  }

  return (
    <WebViewExclude>
      <Query query={GET_MOBILE_NAV_DATA}>
        {({ data, error, loading, }) => {
          if (error || loading) return null;
          const { pageType, canonicalUrl, title, } = data;

          const menu: MainMenuType = (menuItems?.items || []).reduce((acc, item) => {
            if (item.kind === 'purchase') {
              acc.promotions.push(item);
            }
            else if (item.kind === 'site' || item.kind === 'commercial') {
              acc.sites.push(item);
            }
            else {
              acc.items.push(item);
            }

            return acc;
          }, {
            items: [],
            promotions: [],
            sites: [],
          });

          return (
            <MobileNavigationMain
              menu={menu}
              suggestedSections={null}
              menuIsOpen={menuIsOpen}
              menuIsClosing={menuIsClosing}
              toggleMenu={menuItems ? toggleMenu : noop}
              menuRef={menuRef}
              navRefs={[ mainNavRef, sectionNavRef, ]}
              onCloseRef={onCloseRef}
              onSearchToggle={onSearchToggle}
              Search={SearchComponent}
              hideSearch={hideSearch}
              pageType={pageTypeOverwrite || pageType}
              user={user}
              isLoggedIn={isLoggedIn}
              canonicalUrl={canonicalUrl}
              title={title}
            />
          );
        }
      }
      </Query>
    </WebViewExclude>
  );
}
