/* global window */
// @flow
import * as React from 'react';
import { useApolloClient, useQuery, } from 'react-apollo';
import { useFela, } from 'react-fela';
import { useInView, } from 'react-intersection-observer';
import MastheadWrapper from './MastheadWrapper';
import LayoutRow from '../PageLayout/LayoutRow';
import NavigationMenu from '../NavigationMenu/NavigationMenu';
import MastheadSearch from './MastheadSearch/MastheadSearch';
import MastheadUserTools from './MastheadUserTools';
import { MastheadDisplayedProvider, } from './useMastheadDisplayed';
import useMedia from '../../hooks/useMedia';
import throttleFn from '../../utils/throttleFn';
import getTransitionEnd from '../../utils/getTransitionEnd';
import isStickyMastheadDisabledQuery from './queries/isStickyMastheadDisabledQuery.graphql';
import type { ColorPropType, } from '../../flowTypes/ColorPropType';

type BaseProps = {
  contentId: string,
  Search: ?React.ElementType,
  rowBgc: ?ColorPropType,
  mastheadFullWidth: boolean,
  menuItems: ?Object,
  themeColor: ?string,
};
type ArticlePageMastheadProps = BaseProps & {
  shouldDisplay: boolean,
  isSticky: boolean,
  wrapperRef: HTMLElement => void,
};

const mastheadWrapperMiscStyles = ({ theme, themeColor, }) => ({
  display: 'flex',
  position: 'relative',
  // alignItems: 'flex-end',
  alignItems: 'stretch',
  justifyContent: 'center',
  transitionProperty: 'transform',
  ...theme.getDelay('transition', -1),
  ...theme.getDuration('transition', -1),
  ...theme.getTimingFunction('transition', 'linear'),
  borderBottom: [ '1px', 0, 'solid', theme.color(themeColor, 'mastheadBorder'), ],
});

const ArticlePageMasthead = React.memo <
  ArticlePageMastheadProps >(({
    contentId,
    Search,
    rowBgc,
    mastheadFullWidth,
    menuItems,
    wrapperRef,
    isSticky,
    shouldDisplay,
    themeColor,
  }: ArticlePageMastheadProps): React.Node => {
    const { theme, } = useFela();
    return (
      <MastheadDisplayedProvider value={true || shouldDisplay}>
        <LayoutRow
          tagName="header"
          elementRef={wrapperRef}
          namedBgc={
            rowBgc || [
              { until: 's', value: 'transparent', },
              { from: 's', value: [ 'layout', 'rowBg', ], },
            ]
          }
          miscStyles={{
            fontFamily: theme.fontStacks[theme.mastheadStyle.fontStack],
            position: [
              { until: 's', value: 'relative', },
              { from: 's', value: isSticky ? [ 'fixed', 'sticky', ] : 'relative', },
            ],
            width: '100%', // for the 'fixed' position fallback
            top: '0',
            zIndex: theme.getZIndex('masthead'),
            transform: `translateY(${shouldDisplay ? 0 : -100}%)`,
            transitionProperty: 'transform',
            ...theme.getDelay('transition', -1),
            ...theme.getDuration('transition', -1),
            ...theme.getTimingFunction('transition', 'linear'),
          }}
        >
          <MastheadWrapper
            isFullWidth={mastheadFullWidth}
            disableDatetime
            logoSize={3.5}
            themeColor={themeColor}
            logoMiscStyles={{
              display: 'block',
            }}
            disablePanels="bottom"
            panelsResponsiveHiding={[ { until: 's', value: 'sides', }, ]}
            renderStartPanel={(toggleOther, toggleMe) => (
              <React.Fragment>
                <NavigationMenu contentId={contentId} menuItems={menuItems?.[1]} themeColor={themeColor} />
                {Search ? (
                  <Search onClick={toggleOther} themeColor={themeColor} />
                ) : (
                  <MastheadSearch onClick={toggleOther} themeColor={themeColor} />
                )}
              </React.Fragment>
            )}
            renderEndPanel={(toggleOther, toggleMe) => <MastheadUserTools menuItems={menuItems?.[0]} themeColor={themeColor} />}
            miscStyles={mastheadWrapperMiscStyles({ theme, themeColor, })}
          />
        </LayoutRow>
      </MastheadDisplayedProvider>
    );
  });

ArticlePageMastheadDisplayController.defaultProps = {
  rowBgc: null,
  mastheadFullWidth: false,
  Search: null,
  menuItems: null,
  forceStaticPosition: false,
  themeColor: 'defaultTheme',
};

// TODO: rewrite!
export default function ArticlePageMastheadDisplayController({
  contentId,
  Search,
  rowBgc,
  mastheadFullWidth,
  menuItems,
  themeColor,
  forceStaticPosition,
}: BaseProps & { forceStaticPosition: ?boolean, }): React.Node {
  const client = useApolloClient();
  const { data: stickinessDisabledData, } = useQuery(isStickyMastheadDisabledQuery);
  const { isStickyMastheadDisabled = false, } = stickinessDisabledData || {};
  const isMobile = useMedia({ query: { until: 's', }, matchOnServer: true, });
  const stickinessDisabled = isMobile || forceStaticPosition || isStickyMastheadDisabled;
  const [ isSticky, setIsSticky, ] = React.useState(false);
  const [ shouldDisplay, setShouldDisplay, ] = React.useState(true);
  const [ inViewRef, inView, entry, ] = useInView();
  const headerRef = React.useRef(null);
  const headerStaticPosition = React.useRef({ y: 0, });
  const scrollPosition = React.useRef({ y: 0, direction: 0, });
  const yForDiff = React.useRef(null);
  const refCallback = React.useCallback(
    el => {
      inViewRef(el);
      headerRef.current = el;
    },
    [ inViewRef, ]
  );

  const yReachedHeaderStaticPos = React.useCallback(
    // $FlowFixMe - wrong typing for useCallback
    (y: number) => y <= headerStaticPosition.current.y,
    []
  );

  const scrolledUpEnough = React.useCallback(
    // $FlowFixMe - wrong typing for useCallback
    (newY: number, prevScroll: Object) => {
      const direction = newY - prevScroll.y;
      const scrollingUp = direction < 0 || (prevScroll.direction < 0 && prevScroll.y === newY);
      if (!scrollingUp) {
        yForDiff.current = null;
      }
      else if (!yForDiff.current) {
        yForDiff.current = prevScroll.y;
      }
      const yDiff = yForDiff.current ? yForDiff.current - newY : 0;
      return yDiff >= 400;
    },
    []
  );

  React.useEffect(() => {
    if (stickinessDisabled) {
      setIsSticky(false);
      setShouldDisplay(true);
      client.writeData({
        data: {
          isMastheadSticky: false,
        },
      });
    }
  }, [ stickinessDisabled, shouldDisplay, client, ]);

  /* * * intersection observer handler for when header is not sticky * * */
  React.useEffect(() => {
    const header = headerRef.current;
    if (isSticky || stickinessDisabled || !header) return undefined;
    const onTransitionEnd = () => {
      setIsSticky(true);
      header.removeEventListener(getTransitionEnd(header), onTransitionEnd);
    };

    if (!inView && entry) {
      // The header may not always be the top most element on the page.
      // Its static Y position is recorded here for a relative assurance
      // that the behavior on scroll would work as expected.
      const headerBoundingRect = header.getBoundingClientRect();
      const y = window.pageYOffset + headerBoundingRect.y;
      if (y >= 0) {
        header.addEventListener(getTransitionEnd(header), onTransitionEnd);
        headerStaticPosition.current = {
          y: window.pageYOffset + headerBoundingRect.y,
        };
        setShouldDisplay(false);
      }
    }
    else {
      setShouldDisplay(true);
    }

    return () => {
      header.removeEventListener(getTransitionEnd(header), onTransitionEnd);
    };
  }, [ inView, isSticky, entry, stickinessDisabled, forceStaticPosition, ]);

  /* * * scroll handler for when header is sticky * * */
  React.useEffect(() => {
    if (!isSticky || stickinessDisabled) return undefined;
    const handleScroll = throttleFn(() => {
      if (!scrollPosition.current) {
        // (this check is here to appease flow, scrollPosition.current is never falsy)
        scrollPosition.current = { y: 0, direction: 0, };
      }
      const prevScroll = scrollPosition.current;
      const newY = window.pageYOffset;
      const direction = newY - prevScroll.y;
      scrollPosition.current = { y: newY, direction, };
      if (yReachedHeaderStaticPos(newY)) {
        setShouldDisplay(true);
        setIsSticky(false);
        window.removeEventListener('scroll', handleScroll);
      }
      // $FlowFixMe - flow does not recognise 'scrolledUpEnough' as a function
      else if (scrolledUpEnough(newY, prevScroll)) {
        setShouldDisplay(true);
        client.writeData({
          data: {
            isMastheadSticky: true,
          },
        });
      }
      else {
        setShouldDisplay(false);
        client.writeData({
          data: {
            isMastheadSticky: false,
          },
        });
      }
    }, 100);

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [ isSticky, stickinessDisabled, yReachedHeaderStaticPos, scrolledUpEnough, client, ]);

  return (
    <ArticlePageMasthead
      contentId={contentId}
      Search={Search}
      rowBgc={rowBgc}
      mastheadFullWidth={mastheadFullWidth}
      menuItems={menuItems}
      wrapperRef={refCallback}
      isSticky={isSticky}
      shouldDisplay={shouldDisplay}
      themeColor={themeColor}
    />
  );
}
