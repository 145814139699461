// @flow
import * as React from 'react';
import HtzLink from '../HtzLink/HtzLink';
import { getSectionsToDisplay, } from './mobileNavUtils';
import type { MenuItemType, } from './MenuItemType';

type biActionType = ({ actionCode: number, }) => void;
type TopLinksProps = {
  isHomepage: boolean,
  isLoggedIn: boolean,
  suggestedSections: ?MenuItemType[],
  setNavBiAction: ({
    name: string,
    isPersonal?: boolean,
    isMarketing?: boolean,
    featureType?: ?string,
  }) => ?biActionType,
  linkClassName: string,
  css: (Object | (Object => Object)) => string,
  theme: Object,
};

export default function TopLinks({
  isHomepage,
  isLoggedIn,
  suggestedSections,
  setNavBiAction,
  linkClassName,
  css,
  theme,
}: TopLinksProps): React.Node {
  const sections = suggestedSections ? getSectionsToDisplay(suggestedSections) : [];

  const liClassName = css({
    marginBottom: '1rem',
  });

  return (
    <ul
      className={css({
        ...theme.type(1),
      })}
    >
      {isHomepage ? null : (
        <li className={liClassName}>
          <HtzLink
            href="/"
            className={linkClassName}
            onClick={setNavBiAction({ name: theme.mobileNavigationMenuI18n.home, })}
          >
            {theme.mobileNavigationMenuI18n.home}
          </HtzLink>
        </li>
      )}
      {/* <li className={liClassName}>כתבות שקראתי</li> */}
      {sections.map(section => (
        <li key={section.contentId} className={liClassName}>
          <HtzLink
            className={linkClassName}
            href={section.url}
            onClick={setNavBiAction({ name: section.name, isPersonal: true, })}
          >
            {section.name}
          </HtzLink>
        </li>
      ))}
      {isLoggedIn && theme.mobileReadingList ? (
        <li className={liClassName}>
          <HtzLink
            className={linkClassName}
            href={theme.mobileReadingList.url}
            onClick={setNavBiAction({ name: theme.mobileReadingList.text, featureType: 'reading_list', })}
          >
            {theme.mobileReadingList.text}
          </HtzLink>
        </li>
      ) : null}
    </ul>
  );
}
