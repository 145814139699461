// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import type { StyleProps, } from '@haaretz/htz-css-tools';
import MastheadSearch from './MastheadSearch/MastheadSearch';
import LayoutRow from '../PageLayout/LayoutRow';
import MastheadWrapper from './MastheadWrapper';
import NavigationMenu from '../NavigationMenu/NavigationMenu';
import MastheadUserTools from './MastheadUserTools';
import MadorimNavigation from './MadorimNavigation';
import type { ColorPropType, } from '../../flowTypes/ColorPropType';

type HomePageMastheadProps = {
  contentId: string,
  Search?: ?React.ElementType,
  hideSearch: boolean,
  menuItems: ?Object,
  rowBgc: ?ColorPropType,
  wrapperMiscStyles: ?StyleProps,
  disableHomepageLink: boolean,
};

const mastheadWrapperMiscStyles = ({ theme, miscStyles, }) => ({
  position: 'relative',
  padding: [
    { until: 's', value: '2rem  0 1rem 0', },
    { from: 's', value: '2rem 0 0 0', },
  ],
  backgroundColor: [
    {
      until: 's',
      value: theme.color('transparent'),
    },
  ],
  backgroundImage: [
    {
      until: 's',
      value:
        'linear-gradient(to bottom, rgba(0, 0, 0, 0.95), rgba(0, 0, 0, 0.8) 15%, rgba(0, 0, 0, 0))',
    },
  ],
  borderBottom: [
    { from: 's', value: [ '1px', 1, 'solid', theme.color('masthead', 'border'), ], },
  ],
  ...(miscStyles || {}),
});

HomePageMasthead.defaultProps = {
  Search: null,
  hideSearch: false,
  disableHomepageLink: true,
  menuItems: null,
  rowBgc: null,
  wrapperMiscStyles: null,
};

export default function HomePageMasthead({
  contentId,
  menuItems,
  rowBgc,
  wrapperMiscStyles,
  Search,
  hideSearch,
  disableHomepageLink,
}: HomePageMastheadProps): React.Node {
  const { theme, } = useFela();
  return (
    <LayoutRow
      miscStyles={{
        fontFamily: theme.fontStacks[theme.mastheadStyle.fontStack],
        display: [ { until: 's', value: 'none', }, ],
        left: [ { until: 's', value: 0, }, ],
        marginBottom: [ { until: 's', value: '-7rem', }, ],
        position: 'relative',
        zIndex: theme.getZIndex('masthead'),
      }}
      namedBgc={
        rowBgc || [
          { until: 's', value: 'transparent', },
          { from: 's', value: [ 'layout', 'rowBg', ], },
        ]
      }
      tagName="header"
    >
      <MastheadWrapper
        isHomepageVariation
        disableHomepageLink={disableHomepageLink}
        backgroundColor={[
          { until: 's', value: 'transparent', },
          { from: 's', value: [ 'layout', 'containerBg', ], },
        ]}
        miscStyles={mastheadWrapperMiscStyles({
          theme,
          miscStyles: wrapperMiscStyles,
        })}
        datetimeMiscStyles={{ margin: '1rem 0', }}
        // logoSize={[ { until: 'l', value: 4, }, { from: 'l', value: 5, }, ]}
        // logoMiscStyles={{
        //   marginBottom: [ { from: 's', until: 'l', value: '.5rem', }, ],
        //   transitionProperty: 'fill, color,  height, width',
        //   ...theme.getDelay('transition', -1),
        //   ...theme.getDuration('transition', -1),
        //   ...theme.getTimingFunction('transition', 'linear'),
        //   willChange: 'transform',
        //   color: [ { until: 's', value: 'white', }, ],
        //   fill: [ { until: 'l', value: 'transparent', }, { from: 'l', value: [ 'primary', 'base', ], }, ],
        // }}
        panelsResponsiveHiding={[
          { until: 's', value: 'all', },
          { from: 's', until: 'l', value: 'bottom', },
        ]}
        renderStartPanel={(toggleOther, toggleMe) => (
          <React.Fragment>
            <NavigationMenu contentId={contentId} menuItems={menuItems?.[1]} />
            {!hideSearch ? Search ? (
              <Search onClick={toggleOther} />
            ) : (
              <MastheadSearch onClick={toggleOther} />
            ) : null}
          </React.Fragment>
        )}
        renderEndPanel={(toggleOther, toggleMe) => (
          <React.Fragment>
            <MastheadUserTools menuItems={menuItems?.[0]} />
          </React.Fragment>
        )}
        renderBottomPanel={() => (<MadorimNavigation contentId={contentId} menuItems={menuItems?.[2]} />)
        }
      />
    </LayoutRow>
  );
}
