/* global document window */
// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import FocusLock from 'react-focus-lock';
import { type StyleProps, } from '@haaretz/htz-css-tools';
import Portal from '../Portal/Portal';
import useQueryParam from '../../hooks/useQueryParam';

type PropTypes = {
  /** ID for the modal (is generated automaticaly by A11yDialog.js) */
  modalId: string,
  /** Determine which id Portal div gets */
  appendTo: string | HTMLElement | { current: HTMLElement, },
  /** Handle outside content click to close the dialog */
  closeOnOutsideClick: ?boolean,
  /**
   * Transforms dialog to modal, which enable focus lock ,
   * and change style's object position to `fixed`
   * and also passed back to the user by render props.
   */
  isModal: ?boolean,
  /**
   * when the dialog is opened and closed,
   * disable url param dialog state to be set on url,
   * preventing the rerender the page and block double ga4 data to be sent
   */
  shouldSendParamDialogState: ?boolean,
  /**
   *  Determine dialog mode(opend/closed).
   *  Accept `true` or `false`.
   *  Usually passing the state of user's custom component
   */
  isVisible: ?boolean,
  /**
   * The render Props callback
   * This component was built using the render props pattern.
   *
   * Checkout the following link to learn about render props pattern http://bit.ly/2CSxs7g
   *
   * The Dialog Component passes an Object to its render function.
   * @param {Object} options
   * @param {function} options.handleClose - A function used for closing the dialog
   * @param {boolean} options.isModal - Indicates if the dialog is used as part of a modal which obscures
   *                                    the entire ui and prevents any further interaction with it.
   * @param {boolean} options.isVisibile - Indicates the dialog's current state
   */
  render: (options: Object) => void,
  /**
   * An object of attrbutes to set on the DOM element.
   * Passed to the underlying overlay FelaComponent in this component
   */
  overlayAttrs: Object,
  /** set overlay FelaComponent background color */
  overlayBgColor: ?string,
  overlayMiscStyles: ?StyleProps,
  /**
   * A special property holding miscellaneous CSS values that
   * trump all default values. Processed by
   * [`parseStyleProps`](https://Haaretz.github.io/htz-frontend/htz-css-tools#parsestyleprops)
   */
  containerMiscStyles: ?StyleProps,
  /**
   * A special property, will be true when component is modal by style only
   */
  styledModal: ?Boolean,
  /**
   * A focusable element ref, to focus when dialog is open.
   */
  focusableEl: { current: React.ElementType, },
  /** Fela style rule for dialog content */
  dialogContentStyle: Object => Object,
  /** Fela style rule for dialog overlay */
  dialogOverlayStyle: Object => Object,
  /** callback from parent to call on dialog close */
  closeDialog: () => void,
  onAnimationStart: () => void,
  onAnimationEnd: () => void,

};

// $FlowFixMe: forwardRef is missing from React type in current version
const StatelessA11yDialog = React.forwardRef<PropTypes, HTMLInputElement>(
  (
    {
      modalId,
      appendTo,
      isModal,
      shouldSendParamDialogState,
      isVisible,
      overlayAttrs,
      overlayBgColor,
      overlayMiscStyles,
      dialogOverlayStyle,
      closeOnOutsideClick,
      containerMiscStyles,
      closeDialog,
      dialogContentStyle,
      focusableEl,
      render,
      styledModal,
      onAnimationStart,
      onAnimationEnd,
    }: PropTypes,
    containerRef
  ) => {
    const [ urlParamDialogState, setUrlParamDialogState, ] = useQueryParam(modalId, 'replace');

    const visibleSemaphore = React.useRef(isVisible);
    const { css, } = useFela({
      isModal,
      isVisible,
      overlayAttrs,
      overlayBgColor,
      overlayMiscStyles,
      containerMiscStyles,
      styledModal,
    });

    const dialogOverlayClassName = css(dialogOverlayStyle);
    const dialogContentClassName = css(dialogContentStyle);

    const closeHandler = React.useCallback(() => {
      closeDialog();
      setUrlParamDialogState(null);
    }, [ closeDialog, setUrlParamDialogState, ]);

    const handleKeydown = e => {
      if (e.keyCode === 27 && isVisible) {
        closeHandler();
      }
    };

    React.useEffect(() => {
      visibleSemaphore.current = isVisible;
      if (isVisible && isModal && shouldSendParamDialogState) {
        setUrlParamDialogState('open');
      }
    }, [ isVisible, isModal, setUrlParamDialogState, shouldSendParamDialogState, ]);

    React.useEffect(() => {
      if (!isModal) return;
      if (visibleSemaphore.current && urlParamDialogState !== 'open') {
        closeHandler();
      }
    }, [ closeHandler, urlParamDialogState, isModal, ]);

    React.useEffect(() => () => {
      setUrlParamDialogState(urlParamDialogState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Portal host={appendTo}>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div style={{ display: isVisible ? 'block' : 'none', }} onKeyDown={handleKeydown}>
          <FocusLock disabled={!(isVisible && isModal)} autoFocus={false}>
            <div
              className={dialogOverlayClassName}
              {...(closeOnOutsideClick
                ? {
                  onClick: closeHandler,
                  tabIndex: '-1',
                }
                : {})}
            />

            <div
              className={dialogContentClassName}
              role="dialog"
              // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
              tabIndex="0"
              ref={containerRef}
              {...(isVisible ? {} : { 'aria-hidden': 'true', })}
              onAnimationStart={onAnimationStart}
              onAnimationEnd={onAnimationEnd}
            >
              <div role="document" className={css({ width: '100%', height: '100%', })}>
                {render({
                  isVisible,
                  handleClose: closeHandler,
                  isModal,
                  styledModal,
                })}
              </div>
            </div>
          </FocusLock>
        </div>
      </Portal>
    );
  }
);

export default StatelessA11yDialog;

StatelessA11yDialog.defaultProps = {
  closeOnOutsideClick: false,
  isVisible: false,
  isModal: false,
  shouldSendParamDialogState: true,
  overlayBgColor: null,
  overlayAttrs: null,
  overlayMiscStyles: null,
  containerMiscStyles: null,
  focusableEl: null,
  dialogOverlayStyle: null,
  dialogContentStyle: null,
};
