/* global document window */
import React from 'react';
import PropTypes from 'prop-types';
import { parseStyleProps, } from '@haaretz/htz-css-tools';
import buttonHandlers from './utils/concateHandlersToElementId';
import setAriaHidden from './utils/setAriaHidden';
import { stylesPropType, } from '../../propTypes/stylesPropType';
import { attrsPropType, } from '../../propTypes/attrsPropType';
import StatelessA11yDialog from './StatelessA11yDialog';

const dialogOverlayStyle = ({
  theme,
  overlayBgColor,
  overlayMiscStyles,
  isVisible,
  isModal,
  styledModal,
}) => ({
  display: 'block',
  backgroundColor: overlayBgColor || 'rgba(0, 0, 0, 0.66)',
  height: '100%',
  width: '100%',
  position: (isModal || styledModal) ? 'fixed' : 'absolute',
  top: '0',
  left: '0',
  bottom: '0',
  right: '0',
  zIndex: theme.getZIndex('modal'),
  extend: [
    ...(overlayMiscStyles ? parseStyleProps(overlayMiscStyles, theme.mq, theme.type) : []),
  ],
});

const dialogContentStyle = ({ theme, isModal, containerMiscStyles, styledModal, }) => ({
  position: (isModal || styledModal) ? 'fixed' : 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  zIndex: theme.getZIndex('modal', 1),
  extend: [
    ...(containerMiscStyles ? parseStyleProps(containerMiscStyles, theme.mq, theme.type) : []),
  ],
});

class A11yDialog extends React.Component {
  static propTypes = {
    /** Determine which id Portal div gets */
    appendTo: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
      PropTypes.shape({ current: PropTypes.node, }),
    ]).isRequired,
    /** Handle outside content click to close the dialog */
    closeOnOutsideClick: PropTypes.bool,
    /** Set aria-hidden true on root element after dialog was opened */
    elementToHide: PropTypes.string.isRequired,
    /**
     * Transforms dialog to modal, which enable focus lock ,
     * and change style's object position to `fixed`
     * and also passed back to the user by render props.
     */
    isModal: PropTypes.bool,
    /**
   * when the dialog is opened and closed,
   * disable url param dialog state to be set on url,
   * preventing the rerender the page and block double ga4 data to be sent
   */
    shouldSendParamDialogState: PropTypes.bool,
    /**
     *  Determine dialog mode(opend/closed).
     *  Accept `true` or `false`.
     *  Usually passing the state of user's custom component
     */
    isVisible: PropTypes.bool,
    /** Trigger user callback function when modal is closed */
    onClose: PropTypes.func,
    /** Trigger user callback function when modal is opened */
    onOpen: PropTypes.func,
    /** An external alternate to the internal closeDialog function */
    closingFunc: PropTypes.func,
    /**
     * The render Props callback
     * This component was built using the render props pattern.
     *
     * Checkout the following link to learn about render props pattern http://bit.ly/2CSxs7g
     *
     * The Dialog Component passes an Object to its render function.
     * @param {Object} options
     * @param {function} options.handleClose - A function used for closing the dialog
     * @param {boolean} options.isModal - Indicates if the dialog is used as part of a modal which obscures
     *                                    the entire ui and prevents any further interaction with it.
     * @param {boolean} options.shouldSendParamDialogState - Modal state to be set on url
     * @param {boolean} options.isVisibile - Indicates the dialog's current state
     */
    render: PropTypes.func.isRequired,
    /**
     *  An Array contains string element/s id/s to open/close modal.
     *  As each element toggles the state of the dialog between opened and closed.
     *  When passing `toggleRefs` No state nor `isVisible` prop are needed to open/close modal.
     */
    toggleRefs: PropTypes.arrayOf(PropTypes.string),
    /**
     * An object of attrbutes to set on the DOM element.
     * Passed to the underlying overlay FelaComponent in this component
     */
    overlayAttrs: attrsPropType,
    /** set overlay FelaComponent background color */
    overlayBgColor: PropTypes.string,
    /**
     * A special property holding miscellaneous CSS values that
     * trump all default values. Processed by
     * [`parseStyleProps`](https://Haaretz.github.io/htz-frontend/htz-css-tools#parsestyleprops)
     */
    overlayMiscStyles: stylesPropType,
    containerMiscStyles: stylesPropType,

    /**
     * A special property, will be true when component is modal by style only
     */
    styledModal: PropTypes.bool,
    /**
     * A focusable element ref, to focus when dialog is open.
     */
    focusableEl: { current: PropTypes.instanceOf(React.Element), },
    /**
     * A unique id for the modal.
     */
    modalId: PropTypes.string,

    onAnimationEnd: PropTypes.func,
    onAnimationStart: PropTypes.func,
  };

  static defaultProps = {
    toggleRefs: [],
    closeOnOutsideClick: false,
    isVisible: false,
    isModal: false,
    shouldSendParamDialogState: true,
    onClose: null,
    onOpen: null,
    overlayBgColor: null,
    overlayAttrs: null,
    overlayMiscStyles: null,
    containerMiscStyles: null,
    focusableEl: null,
    styledModal: false,
    closingFunc: null,
    modalId: `mid${Math.floor(Math.random() * 10000)}`,
    onAnimationEnd: null,
    onAnimationStart: null,
  };

  state = {
    isVisible: false,
    isMounted: false,
    returnFocusTo: null,
  };

  container = React.createRef();

  componentDidMount = () => {
    const { isVisible, toggleRefs, } = this.props;

    this.setState({ isMounted: true, }, () => {
      if (isVisible) {
        this.openDialog();
      }
    });
    if (Array.isArray(toggleRefs)) {
      toggleRefs.forEach(id => buttonHandlers.setToggleHandler(id, this.toggleDialog));
    }
  };

  componentWillReceiveProps = ({ isVisible, }) => {
    if (isVisible !== this.state.isVisible) {
      this.setState({ isVisible, }, () => (isVisible ? this.openDialog() : this.closeDialog()));
    }
  };

  componentWillUnmount = () => {
    const { toggleRefs, } = this.props;
    if (Array.isArray(toggleRefs)) {
      toggleRefs.forEach(id => buttonHandlers.clearHandler(id, this.toggleDialog));
    }
  };

  openDialog = () => {
    setAriaHidden.set(this.props.elementToHide);

    if (document && this.props.isModal) {
      document.documentElement.style.overflowY = 'hidden';
    }

    this.setState(
      (prevState, props) => (prevState.wasRendered
        ? { isVisible: true, returnFocusTo: document.activeElement, }
        : {
          isVisible: true,
          wasRendered: true,
          returnFocusTo: document.activeElement,
        }),
      () => {
        this.props.onOpen && this.props.onOpen();
        if (this.props.focusableEl && this.props.focusableEl.current) {
          this.props.focusableEl.current.focus();
        }
        else {
          this.container.current.focus();
        }
      }
    );
  };

  closeDialog = () => {
    const { returnFocusTo, } = this.state;
    setAriaHidden.remove(this.props.elementToHide);

    // Reenable page scroll when dialog is a modal.
    if (document && this.props.isModal) {
      document.documentElement.style.overflowY = null;
    }

    this.setState({ isVisible: false, returnFocusTo: null, }, () => {
      this.props.onClose && this.props.onClose();
      returnFocusTo && returnFocusTo.focus();
    });
  };

  toggleDialog = () => {
    this.state.isVisible ? this.closeDialog() : this.openDialog();
  };

  render = () => {
    const {
      appendTo,
      isModal,
      shouldSendParamDialogState,
      render,
      overlayAttrs,
      overlayBgColor,
      overlayMiscStyles,
      containerMiscStyles,
      closeOnOutsideClick,
      focusableEl,
      styledModal,
      closingFunc,
      onAnimationStart,
      onAnimationEnd,
    } = this.props;
    const closeDialog = typeof closingFunc === 'function' ? closingFunc : this.closeDialog;
    return this.state.isMounted ? (
      <StatelessA11yDialog
        styledModal={styledModal}
        modalId={this.props.modalId}
        ref={this.container}
        appendTo={appendTo}
        isModal={isModal}
        shouldSendParamDialogState={shouldSendParamDialogState}
        isVisible={this.state.isVisible}
        overlayAttrs={overlayAttrs}
        overlayBgColor={overlayBgColor}
        overlayMiscStyles={overlayMiscStyles}
        dialogOverlayStyle={dialogOverlayStyle}
        closeOnOutsideClick={closeOnOutsideClick}
        containerMiscStyles={containerMiscStyles}
        closeDialog={closeDialog}
        dialogContentStyle={dialogContentStyle}
        focusableEl={focusableEl}
        render={render}
        onAnimationStart={onAnimationStart}
        onAnimationEnd={onAnimationEnd}
      />
    ) : null;
  };
}

export default A11yDialog;
