// @flow

import { useQuery, } from 'react-apollo';
import { SCRIPTS_QUERY, } from '@haaretz/graphql';
import { useRouter, } from 'next/router';
import useArticleId from './useArticleId';
import usePreview from './usePreview';
import useAuthorId from './useAuthorId';
import useTagId from './useTagId';

export default function useScripts() {
  const articleId = useArticleId();
  const { previewId, isPreview, } = usePreview();
  const authorId = useAuthorId();
  const tagId = useTagId();
  const router = useRouter();

  const skinSectionName = router.query.skinSectionName;


  const path = router.query.path;

  const id = articleId || authorId || tagId || previewId;

  const variables = id ? {
    id,
  } : { path, };

  if (skinSectionName && variables.id) {
    variables.skinSectionName = skinSectionName;
  }

  const { data, loading, error, } = useQuery(SCRIPTS_QUERY, {
    fetchPolicy: 'cache-first',
    skip: isPreview,
    variables,
  });

  if (isPreview) {
    return { scriptsToRender: [], };
  }

  if (loading) return null;
  if (error) {
    console.error('Scripts error', error);
    return null;
  }

  if (!data) return null;

  return data.Page?.scripts;
}
