const mobileNumberParser = (mobileNum, phonePreffix) => {
  if (typeof mobileNum === 'undefined' || mobileNum === null) {
    return { prefix: '', suffix: '', };
  }
  let mobilePrefix;
  let mobileSuffix;

  if (phonePreffix && mobileNum.startsWith('+')) {
    mobilePrefix = phonePreffix;
    mobileSuffix = mobileNum.substring(mobilePrefix.length);
  }
  else if (mobileNum.startsWith('+')) {
    // mobilePrefix = '00';
    // mobileSuffix = mobileNum.substring(1);
    mobilePrefix = mobileNum.substring(0, 4);
    mobileSuffix = mobileNum.substring(4);
  }
  else {
    mobilePrefix = mobileNum.substring(0, 3);
    mobileSuffix = mobileNum.substring(3);
  }
  return { prefix: mobilePrefix, suffix: mobileSuffix, };
};

export { mobileNumberParser, };
