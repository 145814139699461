const normalStyle = theme => ({
  color: theme.color('masthead', 'icon'),
  backgroundColor: theme.color('transparent'),
});

const openedStyle = theme => ({
  backgroundColor: theme.color('masthead', 'bgMenu'),
  color: theme.color('neutral', '-10'),
});

const focusedStyle = theme => ({
  color: theme.color('white'),
  backgroundColor: theme.color('masthead', 'bgButtonHover'),
  outline: 'none',
});

export default ({ theme, isOpen = false, }) => ({
  transitionProperty: 'all',
  ...(isOpen ? openedStyle(theme) : normalStyle(theme)),

  '&:hover': focusedStyle(theme),
  '&:focus': focusedStyle(theme),

  ...theme.getTransition(1, 'swiftIn'),
});
