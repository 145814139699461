// @flow

import { useQuery, } from 'react-apollo';
import { PAYWALL_TYPE_QUERY, } from '@haaretz/graphql';

import { useRouter, } from 'next/router';
import useArticleId from './useArticleId';
import usePreview from './usePreview';
import useLiveBlogQueryVariables from './useLiveBlogQueryVariables';

const paywallTypeMap = new Map([
  [ 'open', 'open', ],
  [ 'premium', 'premium', ],
  [ 'free', 'free', ],
  [ 'highlight', 'highlight', ],
]);

const defaultPaywallType = 'open';

export default function usePaywallType() {
  const articleId = useArticleId();
  const { previewId, } = usePreview();
  const router = useRouter();

  const path = router.query.path;

  const id = articleId || previewId;

  const queryVariables = useLiveBlogQueryVariables(articleId);

  const { data, } = useQuery(PAYWALL_TYPE_QUERY, {
    fetchPolicy: 'cache-first',
    variables: id ? {
      ...queryVariables,
      id,
    } : { path, },
  });


  const paywallType: string = data?.Page?.slots?.article?.paywall
    || (router.query || {}).paywallType
    || defaultPaywallType;

  const isPremiumContent = paywallType === paywallTypeMap.get('premium');
  const isSuperContent = paywallType === paywallTypeMap.get('highlight');
  const isFree = paywallType === paywallTypeMap.get('free');
  const isOpen = !(isPremiumContent || isSuperContent || isFree);

  return {
    paywallType: paywallTypeMap.get(paywallType) || defaultPaywallType,
    isPremiumContent,
    isSuperContent,
    isFree,
    isOpen,
  };
}
