/* global document sessionStorage */
import React from 'react';
import PropTypes from 'prop-types';
import { UserService, } from '@haaretz/htz-user-utils';
import UserDispenser from './UserDispenser';

const propTypes = {
  render: PropTypes.func.isRequired,
};
const defaultProps = {};

class Logout extends React.Component {
  getLogoutFunction = (user, plantCookies) => function logout() {
    const userService = new UserService({
      plantCookiesCallback: plantCookies,
    });
    return userService.logout({ user, });
  };

  render() {
    return (
      <UserDispenser
        render={({ isLoggedIn, user, plantCookies, }) => {
          const logout = this.getLogoutFunction(user, plantCookies);
          const logoutAndReload = onLogout => logout().then(() => {
            sessionStorage.removeItem('userProduct');

            if (typeof onLogout === 'function') {
              return onLogout();
            }

            return reloadPage();
          })
            .catch(err => console.log('error logging out !!!!!!', err));
          return this.props.render({ logout: logoutAndReload, logoutWithoutReload: logout, });
        }}
      />
    );
  }
}

Logout.propTypes = propTypes;
Logout.defaultProps = defaultProps;

function reloadPage() {
  document.location.reload();
}

export default Logout;
