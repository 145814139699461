// @flow

import React, { type Node, Fragment, } from 'react';
import useWebViewChecker from '../../hooks/useWebViewChecker';

type WebViewExcludeProps = {
  children?: any,
};


function WebViewExclude({ children, }: WebViewExcludeProps): Node {
  const isWebView = useWebViewChecker();

  if (isWebView) return null;

  return <Fragment>{children}</Fragment>;
}

WebViewExclude.defaultProps = {
  children: null,
};

export default WebViewExclude;
