// @flow

import * as React from 'react';
import { useFela, } from 'react-fela';
import { type StyleProps, } from '@haaretz/htz-css-tools';
import IconSearch from '../../Icon/icons/IconSearch';
import ClickArea from '../../ClickArea/ClickArea';
import VisuallyHidden from '../../VisuallyHidden/VisuallyHidden';

type SearchButtonPropsType = {
  onClick: () => void,
  isActive: boolean,
  buttonRef: ?any,
  showText: boolean,
  rippleColor: ?string,
  miscStyles: ?StyleProps,
};

function SearchButtonComponent(props: SearchButtonPropsType) {
  const { buttonRef, isActive, onClick, showText, rippleColor, miscStyles, } = props;
  const { theme, } = useFela({ miscStyles, });
  const TextWrapper = showText ? 'div' : VisuallyHidden;

  return (
    <ClickArea
      ref={buttonRef}
      size={6}
      attrs={{
        'aria-expanded': isActive,
        'aria-label': isActive
          ? theme.headerSearchI18n.a11yTexts.close
          : theme.headerSearchI18n.a11yTexts.execSearch,
      }}
      onClick={onClick}
      miscStyles={{ fontWeight: 'inherit', ...(miscStyles || {}), }}
      {...(rippleColor ? { rippleColor, } : {})}
    >
      {showText || <IconSearch size={5} />}
      <TextWrapper>{theme.headerSearchI18n.buttonText}</TextWrapper>
    </ClickArea>
  );
}

SearchButtonComponent.defaultProps = {
  isActive: false,
  showText: false,
  onClick: null,
  buttonRef: null,
  miscStyles: null,
  rippleColor: null,
};

// TODO: We need to update flow to a newer version
// that includes a definition for `forwardRef`.
// Until then:
// $FlowFixMe
const SearchButton = React.forwardRef((props, ref) => (
  <SearchButtonComponent {...props} buttonRef={ref} />
));

export default SearchButton;
