/* global document */
import React, { useRef, Fragment, } from 'react';
import PropTypes from 'prop-types';
import { useFela, } from 'react-fela';
import Button from '../../Button/Button';
import DropdownList from '../../DropdownList/DropdownList';
import Item from '../../DropdownList/DropdownItem';
import HtzLink from '../../HtzLink/HtzLink';
import Logout from '../../User/Logout';
import UserButton from './UserButton';
import useMastheadDisplayed from '../useMastheadDisplayed';
import { dropdownItemStyle, dropdownListStyle, } from '../mastheadDropdownListStyle';
import useAppURL from '../../../hooks/useAppURL';

/**
 * A user menu component for the page header. A component which receives
 * a user name to generate a welcome string,
 * an array of links to generate a menu list,
 * and it also generate a signout function.
 */
MastheadUserMenu.propTypes = {
  /** A `string` of the user name to display. */
  userName: PropTypes.string,
  /** A `string` of the user name to display. */
  userType: PropTypes.string,
  biAction: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  menuItems: PropTypes.object.isRequired,
};
MastheadUserMenu.defaultProps = {
  userName: null,
  userType: null,
  biAction: null,
};
export default function MastheadUserMenu({ biAction, userName, userType, themeColor, menuItems, disableSeperator, }) {
  const buttonRef = useRef(null);
  const { css, theme, } = useFela();
  const mastheadDisplayed = useMastheadDisplayed();
  const loginURL = useAppURL('login');

  if (!userName) {
    return (
      <HtzLink
        attrs={{ 'data-test': 'loginButton', }}
        href={loginURL}
        onClick={
          biAction
            ? () => biAction({
              actionCode: 51,
              feature: 'header_hello_login',
              featureType: 'Content',
              campaignName: 'התחברות',
              campaignDetails: 'התחברות',
            })
            : null
        }
        content={<UserButton isButton={false} themeColor={themeColor} disableSeperator={disableSeperator} />}
      />
    );
  }

  const items = Array.isArray(menuItems?.items) ? menuItems.items : [];

  const initialCombinedItems = items.map(item => (
    <Item
      key={item.name}
      {...item}
      onClick={
        biAction
          ? () => biAction({
            actionCode: item.biActionCode ? item.biActionCode : 133,
            feature: 'Account Ruler on Header',
            featureType: 'Marketing',
            additionalInfo: { url: item.url, name: item.name, },
          })
          : null
      }
      miscStyles={{
        backgroundColor: theme.color('masthead', 'bgMenu'),
        ':hover': {
          backgroundColor: theme.color('masthead', 'bgMenuHover'),
        },
        ':focus': {
          backgroundColor: theme.color('masthead', 'bgMenuHover'),
        },
        ':active': {
          backgroundColor: theme.color('masthead', 'bgMenuHover'),
        },
      }}
    />
  ));
  const combinedItems = [
    ...initialCombinedItems,
    <Logout
      key="logout"
      render={({ logout, }) => (
        <Button
          boxModel={{ vp: 1, hp: 2, }}
          isFlat
          isFull
          isHard
          fontSize={-2}
          variant="negativeOpaque"
          miscStyles={{
            display: 'flex',
            justifyContent: 'flex-start',
          }}
          onClick={() => {
            biAction && biAction({ actionCode: 53, });
            logout();
            // logout().then(() => onLogout());
          }}
          attrs={{ 'data-test': 'logoutButton', }}
        >
          <span
            className={css({
              marginInlineEnd: 'auto',
              color: theme.color('neutral', '-10'),
            })}
          >
            {theme.userMenuI18n.logout}
          </span>
        </Button>
      )}
    />,
  ];

  return (
    <DropdownList
      mainMenuStyle={{ position: 'relative', }}
      onClose={() => {
        if (document.activeElement !== document.body) return;
        buttonRef.current && buttonRef.current.focus();
      }}
      render={({ renderButton, ListWrapper, isOpen, closeList, }) => {
        if (isOpen && !mastheadDisplayed) closeList();
        return (
          <Fragment>
            {renderButton(({ toggleState, }) => (
              <UserButton
                isOpen={isOpen}
                themeColor={themeColor}
                onClick={
                  !isOpen && biAction
                    ? () => {
                      toggleState();
                      biAction({ actionCode: 52, });
                    }
                    : toggleState
                }
                userName={userName}
                userType={userType}
                buttonRef={buttonRef}
                disableSeperator={disableSeperator}
              />
            ))}
            {isOpen ? (
              <ListWrapper
                listStyle={{
                  ...dropdownListStyle(theme),
                  ...theme.userMenuI18n.menuPos,
                }}
                itemStyle={dropdownItemStyle(theme)}
                closeList={closeList}
              >
                {combinedItems}
              </ListWrapper>
            ) : null}
          </Fragment>
        );
      }}
    />
  );
}
