// @flow
import * as React from 'react';
import dynamic from 'next/dynamic';
import ClickArea from '../ClickArea/ClickArea';
import IconAvatar from '../Icon/icons/IconAvatar';
import IconSettings from '../Icon/icons/IconSettings';
import IconLogout from '../Icon/icons/IconLogout';
import IconPhone from '../Icon/icons/IconPhone';
import Logout from '../User/Logout';
import VisuallyHidden from '../VisuallyHidden/VisuallyHidden';
import HtzLink from '../HtzLink/HtzLink';
import { greetUser, } from './mobileNavUtils';
import useAppURL from '../../hooks/useAppURL';

const IconHaaretz = dynamic(() => import('../Icon/icons/IconAlefLogo'), {
  loading: () => null,
  ssr: false,
});
const IconTheMarker = dynamic(() => import('../Icon/icons/IconTheMarker'), {
  loading: () => null,
  ssr: false,
});

const logo = { htz: IconHaaretz, tm: IconTheMarker, default: IconHaaretz, };

const menuHeaderStyle = ({ theme, withPromotion, }) => ({
  gridArea: '1 / 1 / 2 / 4',
  zIndex: 1,
  display: 'grid',
  gridColumnGap: '2rem',
  gridTemplateAreas: `
  ". avatar   buttons ."
  ". greeting greeting ."
  `,
  gridTemplateColumns: '0 auto 1fr 0',
  gridTemplateRows: '7.5rem 1fr',
  color: withPromotion ? theme.color('primary', '+1') : theme.color('white'),
  ...theme.type(1),
  fontWeight: 'bold',
  '-webkit-tap-highlight-color': 'transparent',
});

const avatarWrapperStyles = ({ theme, }) => ({
  gridArea: 'avatar',
  marginTop: '-2rem',
  color: theme.color('white'),
  backgroundColor: theme.color('mobileNav', 'avatarBg'),
  width: '8rem',
  height: '8rem',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: '100%',
  overflow: 'hidden',
  display: 'grid',
  justifyContent: 'center',
});

const promotionLinkStyles = ({ theme, }) => ({
  color: theme.color('neutral', '+1'),
  gridArea: 'greeting',
  width: 'fit-content',
  display: 'flex',
  ':visited': {
    color: theme.color('neutral', '+1'),
  },
  ':hover': {
    color: theme.color('neutral', '+1'),
  },
  ':active': {
    color: theme.color('neutral', '+1'),
  },
});

const rippleColor = 'white';

const oneHour = 3600000;

type UserType = { type: ?string, firstName: ?string, id: ?string, university: boolean, };

type MenuHeaderProps = {
  isLoggedIn: boolean,
  user: UserType,
  isLoggedIn: boolean,
  promotion: ?{ url: string, name: string, },
  withPromotion: boolean,
  Search: React.ElementType,
  hideSearch: boolean,
  onSearchToggle: boolean => void,
  biAction: ?({ actionCode: number, additionalInfo?: Object, }) => void,
  css: (Object | (Object => Object)) => string,
    theme: Object,
};

type GreetProps = {
  user: UserType,
  isLoggedIn: boolean,
  theme: {
    greetingsI18n: Object,
  },
  css: Object => string,
};

function Greet({ user, isLoggedIn, css, theme, }: GreetProps): React.Node {
  const [ greeting, setGreeting, ] = React.useState(greetUser({ isLoggedIn, user, theme, }));
  React.useEffect(() => {
    setGreeting(greetUser({ isLoggedIn, user, theme, }));
    const intervalId = setInterval(() => {
      setGreeting(greetUser({ isLoggedIn, user, theme, }));
    }, oneHour);
    return () => {
      clearInterval(intervalId);
    };
  }, [ isLoggedIn, user, theme, ]);
  return <div className={css({ gridArea: 'greeting', alignSelf: 'center', })}>{greeting}</div>;
}

export default function MobileMenuHeader({
  isLoggedIn,
  user,
  Search,
  hideSearch,
  onSearchToggle,
  withPromotion,
  promotion,
  biAction,
  css,
  theme,
}: MenuHeaderProps): React.Node {
  const loginURL = useAppURL('login', `?htm_source=site&htm_medium=navigation&htm_campaign=login&utm_content=${theme.mobileNavigationMenuI18n.login.utmContent}`);
  const personalAreaURL = useAppURL('react', '/account/personal-area');

  const onSearchOpen = React.useCallback(() => {
    onSearchToggle(true);
    biAction
      && biAction({
        actionCode: 143,
        additionalInfo: { type: 'inner icon', },
        pageType: 'Search',
      });
  }, [ onSearchToggle, biAction, ]);
  const Logo = logo[theme.logoType] || logo.default;

  return (
    <div
      className={css(menuHeaderStyle)}
      data-google-interstitial={false}
    >
      {withPromotion ? (
        <Logo
          size={4}
          miscStyles={{ gridArea: 'avatar', alignSelf: 'end', marginBottom: '1rem', }}
          color={[ 'primary', '+1', ]}
          fill={[ 'mobileNav', 'headerIcon', ]}
          aria-hidden
        />
      ) : isLoggedIn ? (
        <div className={css(avatarWrapperStyles)}>
          <IconAvatar size={9} miscStyles={{ transform: 'translateY(2px)', }} aria-hidden />
        </div>
      ) : null}
      <div
        className={css({
          gridArea: 'buttons',
          alignSelf: 'end',
          justifySelf: 'end',
          display: 'flex',
          '& > *:not(:last-child)': { marginEnd: '1rem', },
          // Images planted upon logout cause a minor, momentary layout shift.
          // Next line makes sure that shift does not occur.
          '& > img': { display: 'none', },
        })}
      >
        {!hideSearch ? (
          <Search
            isMobile
            rippleColor={rippleColor}
            showText={false}
            onOpen={onSearchOpen}
            onClose={onSearchToggle}
          />
        ) : null}

        {isLoggedIn ? (
          <React.Fragment>
            <ClickArea
              size={6}
              rippleColor={rippleColor}
              href={theme.userMenuI18n.contactUsUrl}
              onClick={
                biAction
                  ? () => {
                    biAction({
                      actionCode: 122,
                      feature: 'header link',
                      featureType: 'Content',
                    });
                  }
                  : null
              }
            >
              <IconPhone size={2.2} />
              <VisuallyHidden>{theme.userMenuI18n.contactUs}</VisuallyHidden>
            </ClickArea>
            {theme.personalAreaI18n && (
              <ClickArea
                size={6}
                rippleColor={rippleColor}
                href={personalAreaURL}
                onClick={
                  biAction
                    ? () => {
                      biAction({
                        actionCode: 133,
                        feature: 'header link',
                        featureType: 'personal_area',
                      });
                    }
                    : null
                }
              >
                <IconSettings size={5} aria-hidden />
                <VisuallyHidden>{theme.personalAreaI18n.urlText}</VisuallyHidden>
              </ClickArea>
            )}
            <Logout
              render={({ logout, }) => (
                <ClickArea
                  size={6}
                  rippleColor={rippleColor}
                  onClick={
                    biAction
                      ? () => {
                        biAction({ actionCode: 53, });
                        logout();
                      }
                      : logout
                  }
                  attrs={{ 'data-test': 'logoutButtonMobile', }}
                >
                  <IconLogout size={4.4} aria-hidden />
                  <VisuallyHidden>{theme.userMenuI18n.logout}</VisuallyHidden>
                </ClickArea>
              )}
            />
          </React.Fragment>
        ) : (
          <ClickArea
            size={6}
            rippleColor={rippleColor}
            href={loginURL}
            attrs={{ 'data-test': 'loginButtonMobile', }}
            onClick={
              biAction
                ? () => {
                  biAction({
                    actionCode: 51,
                    feature: 'Navigation',
                    featureType: 'Marketing',
                    campaignName: 'login',
                    campaignDetails: theme.mobileNavigationMenuI18n.login.campaignDetailsIcon,
                  });
                }
                : null
            }
          >
            <IconAvatar size={5} aria-hidden />
            <VisuallyHidden>{theme.userMenuI18n.noUserData}</VisuallyHidden>
          </ClickArea>
        )}
      </div>
      {withPromotion && !isLoggedIn ? (
        <HtzLink
          href={loginURL}
          className={css(promotionLinkStyles)}
          attrs={{ 'data-test': 'loginButtonTextMobile', }}
          onClick={biAction ? () => biAction({
            actionCode: 51,
            feature: 'Navigation',
            featureType: 'Marketing',
            campaignName: 'login',
            campaignDetails: theme.mobileNavigationMenuI18n.login.campaignDetailsText,
          }) : null}
        >
          {theme.mobileNavigationMenuI18n.login.text}
          <span
            className={css({
              marginStart: '1rem',
              transform: 'translateY(1px)',
            })}
          >
            {'>'}
          </span>
        </HtzLink>
      ) : (
        <Greet user={user} isLoggedIn={isLoggedIn} css={css} theme={theme} />
      )}
    </div>
  );
}
