/* global document */
import { enableBodyScroll, disableBodyScroll, clearAllBodyScrollLocks, } from 'body-scroll-lock';

export default function togglePageScroll(
  isOn,
  { targetElement = null, clearAll = false, allowStyleChange = true, } = {}
) {
  if (typeof document !== 'undefined') {
    if (targetElement) {
      if (isOn) disableBodyScroll(targetElement);
      else enableBodyScroll(targetElement);
    }
    else if (clearAll) {
      clearAllBodyScrollLocks();
    }
    if (!allowStyleChange) return;
    const html = document.documentElement;
    html.style.overflowY = isOn ? 'hidden' : null;
    // todo: remove the touchAction disabling once the backdrop scrolling issue is fully resolved
    html.style.touchAction = isOn ? 'none' : null;
  }
}
