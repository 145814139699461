/* global document */
import React, { useState, useEffect, useRef, useCallback, } from 'react';
import PropTypes from 'prop-types';
// import FocusLock from 'react-focus-lock';
import { useFela, } from 'react-fela';
import ListWrapper from './ListWrapper';

DropdownList.propTypes = {
  /**
   * A prop that contains a function that (with the help of
   * this component) will render a fragment of the final outcome.
   */
  render: PropTypes.func.isRequired,
  /**
   * A style object for the button that opens the dropdown.
   * The main button must be `position: relative`,
   * the sub-dropdown will defaulted `static`.
   */
  // eslint-disable-next-line react/forbid-prop-types
  mainMenuStyle: PropTypes.object,
  /**
   * A prop should to be sent to sub-list only.
   * Allows arrow key to close the sub-list.
   */
  isLast: PropTypes.bool,
  /**
   * This component must get some extra props to it's ListWrapper and ListItem:
   * `listStyle` a style object to be used by the <ul>,
   * `itemStyle` a style object to be used by the <li>,
   * If none is sent there will be no style.
   */
  onClose: PropTypes.func,
};

DropdownList.defaultProps = {
  mainMenuStyle: { position: 'static', },
  isLast: false,
  onClose: null,
};

function addListeners(listeners) {
  setListeners(true, listeners);
}

function removeListeners(listeners) {
  setListeners(false, listeners);
}

function setListeners(isAdd, listeners) {
  const eventSetter = isAdd ? 'addEventListener' : 'removeEventListener';
  const eventNames = Object.keys(listeners);

  eventNames.forEach(evtName => {
    const handlers = listeners[evtName];
    handlers.forEach(listener => document[eventSetter](evtName, listener));
  });
}
// This isn't a publically consumable component. prop-types
// are assigned to the wrapping component.
export default function DropdownList({ render, mainMenuStyle, isLast, onClose, }) {
  const { css, theme, } = useFela();
  const [ isOpen, setIsOpen, ] = useState(false);
  const hasEvents = useRef(false);
  const wrapperRef = useRef(null);
  const className = css(mainMenuStyle);
  const direction = theme.direction;

  const toggleState = useCallback(() => {
    setIsOpen(!isOpen);
  }, [ isOpen, ]);

  const eventListeners = React.useMemo(
    () => ({
      click: [
        function handleOutsideClick(evt) {
          if (isOpen && wrapperRef.current && !wrapperRef.current.contains(evt.target)) {
            setIsOpen(false);
          }
        },
      ],
      keydown: [
        function handleEscape(evt) {
          const key = evt.which || evt.keyCode;
          if (isOpen && key === 27) setIsOpen(false);
        },
        function handleArrowKey(evt) {
          const key = evt.which || evt.keyCode;
          const backKeyCode = direction === 'rtl' ? 39 : 37;
          if (isOpen && isLast && key === backKeyCode) setIsOpen(false);
        },
      ],
    }),
    [ direction, isLast, isOpen, ]
  );

  useEffect(() => {
    if (isOpen && hasEvents.current === false) {
      addListeners(eventListeners);
      hasEvents.current = true;
    }
    else if (!isOpen && hasEvents.current === true) {
      removeListeners(eventListeners);
      hasEvents.current = false;
      onClose && onClose();
    }
  }, [ eventListeners, isOpen, onClose, ]);

  function openList() {
    setIsOpen(true);
  }

  function closeList() {
    setIsOpen(false);
  }

  function renderButton(buttonRenderer) {
    return buttonRenderer({
      toggleState,
      closeList,
      openList,
    });
  }

  return (
    <div className={className} ref={wrapperRef}>
      {render({
        renderButton,
        ListWrapper,
        isOpen,
        closeList,
      })}
    </div>
  );
}
