import React from 'react';
import { useFela, } from 'react-fela';
import PropTypes from 'prop-types';

import ClickArea from '../ClickArea/ClickArea';
import hoverableButtonRule from './hoverableButtonRule';
import IconReading from '../Icon/icons/IconReading';
import VisuallyHidden from '../VisuallyHidden/VisuallyHidden';

MastheadReadingList.propTypes = {
  biAction: PropTypes.func,
  themeColor: PropTypes.string,
};

MastheadReadingList.defaultProps = {
  biAction: null,
  themeColor: 'defaultTheme',
};
export default function MastheadReadingList({ biAction, themeColor, }) {
  const { theme, } = useFela();
  const { url, a11yText, } = theme.readingListMenuI18n;
  const miscStyles = hoverableButtonRule({ theme, themeColor, });

  const colorBase = themeColor === 'blackTheme' || themeColor === 'yellowTheme'
    ? themeColor
    : 'defaultTheme';
  const color = theme.color(colorBase, 'mastheadIcon');

  return (
    <ClickArea
      href={url}
      onClick={() => {
        biAction({
          actionCode: 147,
          feature: 'header link',
          featureType: 'Content',
        });
      }}
      size={6}
      miscStyles={{
        ...miscStyles,
        color,
        position: 'relative',
        ':visited': { color, },
        ':hover': {
          ':before': {
            content: '""',
            position: 'absolute',
            start: '-1px',
            top: '0',
            width: '1px',
            height: '100%',
            backgroundColor: themeColor === 'blackTheme' || themeColor === 'yellowTheme' ? null : theme.color('neutral', '-10'),
          },
        },
      }}
    >
      <IconReading size={3.3} attrs={{ 'aria-hidden': true, }} />
      <VisuallyHidden>{a11yText}</VisuallyHidden>
    </ClickArea>
  );
}
