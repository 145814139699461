/* global window */
// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import { useInView, } from 'react-intersection-observer';
import NavBar from './NavBar';
import ClickArea from '../ClickArea/ClickArea';
import MobileNavigationMenu from './MobileNavigationMenu';
import useMedia from '../../hooks/useMedia';
import EventTracker from '../../utils/EventTracker';
import GET_USER_BY_MAIL from '../Account/queries/getUserByMail.graphql';
import Query from '../ApolloBoundary/Query';
import type { MenuItemType, MainMenuType, } from './MenuItemType';

type htmlRefType = { current: null | HTMLElement, };

type MobileNavigationMainProps = {
  menu: ?MainMenuType,
  suggestedSections: ?MenuItemType[],
  pageType: string,
  canonicalUrl: string,
  user: { type: ?string, firstName: ?string, id: ?string, university: boolean, },
  isLoggedIn: boolean,
  menuIsOpen: boolean,
  menuIsClosing: boolean,
  toggleMenu: () => void,
  onSearchToggle: boolean => void,
  menuRef: { current: null | HTMLDivElement, },
  navRefs: [ htmlRefType, htmlRefType, ],
  onCloseRef: { current: null | () => void, },
  Search: React.ElementType,
  hideSearch: boolean,
};

type ShapeShifterProps = { shiftShape: boolean => void, };
function ShapeShifter({ shiftShape, }: ShapeShifterProps): React.Node {
  const { css, } = useFela();
  const [ ref, inView, entry, ] = useInView();
  React.useEffect(() => {
    if (!entry) return;
    shiftShape(inView);
  }, [ inView, entry, shiftShape, ]);

  return (
    <div
      ref={ref}
      className={css({
        position: 'absolute',
        zIndex: -1000,
        top: '0',
        end: '0',
        height: '50vh',
        width: '3rem',
        pointerEvents: 'none',
      })}
    />
  );
}

type getBarActionsProps = {
  Search: React.ElementType,
  hideSearch: boolean,
  promotion: ?{ url: string, name: string, },
  withPromotion: boolean,
  showHomepageActions: boolean,
  theme: Object,
  biAction: ?({ actionCode: number, additionalInfo?: Object, }) => void,
  showPromotionAnimation: boolean,
};

const getBarActions = ({
  Search,
  hideSearch,
  withPromotion,
  promotion,
  showHomepageActions,
  theme,
  biAction,
  showPromotionAnimation,
  userProductType,
  isPartialSubscription,
  isFullSubscription,
}: getBarActionsProps): React.Node => (showHomepageActions || showPromotionAnimation
  ? [
    withPromotion && promotion ? (
      <ClickArea
        href={promotion.url.replace('navigation_default', 'hello_default')}
        onClick={
          biAction
            ? () => biAction({
              actionCode: 3,
              feature: 'Navigation',
              featureType: 'Marketing',
              campaignName: isPartialSubscription ? 'upsell' : 'subscription',
              campaignDetails: theme.mobileNavigationMenuI18n.subscribe.campaignDetails,
            })
            : null
        }
        miscStyles={{
          '&:hover': {
            color: theme.color('sales', '+2'),
          },
          '&:active': {
            color: theme.color('sales', '+2'),
          },
          '&:focus': {
            color: theme.color('sales', '+2'),
          },
          color: theme.color('sales', '+2'),
          pointerEvents: 'auto',
          ...(showPromotionAnimation ? {
            animationDuration: '0.8s',
            animationTimingFunction: 'ease',
            animationName: {
              '0%': { opacity: 0, },
              '50%': { opacity: 0, },
              '100%': { opacity: 1, },
            },
          } : {}),
        }}
        attrs={{
          'data-google-interstitial': false,
          'data-test': 'subscribeButtonMobile',
        }}
      >
        {promotion.name || theme.mobileNavigationMenuI18n.subscribe.text}
      </ClickArea>
    ) : (
      !hideSearch ? (
        <Search
          key="search"
          isMobile
          showText
          onOpen={
          biAction
            ? () => {
              biAction({
                actionCode: 143,
                additionalInfo: { type: 'outer link', },
              });
            }
            : null
        }
        />
      ) : null
    ),
    <ClickArea
      key="popular"
      size={6}
      href={theme.mobileNavigationMenuI18n.promotedLink.href}
      onClick={
        biAction
          ? () => {
            biAction({ actionCode: 149, });
          }
          : null
      }
      attrs={{ 'data-google-interstitial': false, }}
    >
      {theme.mobileNavigationMenuI18n.promotedLink.text}
    </ClickArea>,
  ]
  : null);

export default function MobileNavigationMain({
  menu,
  suggestedSections,
  pageType,
  canonicalUrl,
  user,
  isLoggedIn,
  menuIsOpen,
  menuIsClosing,
  toggleMenu,
  menuRef,
  navRefs,
  onCloseRef,
  onSearchToggle,
  Search,
  hideSearch,
}: MobileNavigationMainProps): React.Node {
  const [ baseButtonShape, setBaseButtonShape, ] = React.useState('Logo');
  const [ isHomepage, setIsHomepage, ] = React.useState(pageType === 'homepage');
  const isMobile = useMedia({ query: { until: 's', }, matchOnServer: true, });

  React.useEffect(() => {
    // this is here for the Finance app, where pageType is always 'finance'
    setIsHomepage(
      pageType === 'homepage' || (pageType === 'finance' && window.location.pathname === '/')
    );
  }, [ canonicalUrl, pageType, ]);

  const buttonShape = menuIsOpen && !menuIsClosing ? 'Close' : baseButtonShape;

  const showHomepageActions = [ 'homepage', 'section', 'finance', 'author', 'personal', 'singlePage', ].includes(pageType);

  const showPromotionAnimation = !showHomepageActions && menuIsOpen && !menuIsClosing;

  const promoItems = menu && menu.promotions;

  const subscriptionNames = {
    274: 'BOTH',
    243: 'paying_htz',
    273: 'paying_tm',
    282: 'INACTIVE',
    239: 'paying_hdc',
  };

  const [ showPromotionButton, setIsShowPromotionButton, ] = React.useState(true);

  const { css, theme, } = useFela({
    menuIsOpen,
    menuIsClosing,
    withShareBar: !showHomepageActions,
    showPromotionButton,
  });

  return (
    <Query
      query={GET_USER_BY_MAIL}
      skip={typeof document === 'undefined' || !user?.email}
      variables={{
        email: user?.email,
      }}
    >
      {({ loading, error, data, refetch, }) => {
        let isFullSubscription;
        let userProductType = user.type;
        let isPartialSubscription;

        if (error || loading) {
          isFullSubscription = false;
          userProductType = user.type;
        }
        else if (data?.userByMail) {
          const { ...personalInfo } = data?.userByMail || {};
          const prodNum = personalInfo?.products?.filter(product => {
            if (product.prodNum === 274 && product.status === 'SUBSCRIBED') {
              return true;
            } if ((product.prodNum === 243 && product.status === 'SUBSCRIBED')
            || (product.prodNum === 273 && product.status === 'SUBSCRIBED')) {
              return true;
            }
            return null;
          })?.[0]?.prodNum;
          userProductType = (prodNum && subscriptionNames[prodNum]) || user.type;
          isPartialSubscription = userProductType?.includes('paying_tm') || userProductType?.includes('paying_htz');
          isFullSubscription = (userProductType === 'BOTH');
        }

        const withPromotion = (menuIsOpen && isPartialSubscription) || (userProductType === 'anonymous') || (userProductType === 'registered');
        setIsShowPromotionButton(!isFullSubscription);

        const promotionItem = withPromotion ? promoItems.filter(item => item.includeUserTypes.includes(userProductType))?.[0]
          : theme.navbarSubscriptionI18n && theme.navbarSubscriptionI18n;

        return (
          <EventTracker>
            {({ biAction, }) => (
              <>
                <NavBar
                  toggleMenu={toggleMenu}
                  buttonShape={buttonShape}
                  menuIsOpen={menuIsOpen}
                  css={css}
                  theme={theme}
                  biAction={biAction}
                  showPromotionAnimation={showPromotionAnimation}

                >
                  {getBarActions({
                    promotion: promotionItem,
                    withPromotion,
                    Search,
                    hideSearch,
                    biAction,
                    showHomepageActions,
                    theme,
                    showPromotionAnimation,
                    userProductType,
                    isPartialSubscription,
                    isFullSubscription,
                  })}
                </NavBar>
                {menu ? (
                  <MobileNavigationMenu
                    menu={menu}
                    suggestedSections={suggestedSections}
                    isHomepage={isHomepage}
                    isLoggedIn={isLoggedIn}
                    user={user}
                    withPromotion={withPromotion}
                    closingFunc={toggleMenu}
                    menuIsOpen={menuIsOpen}
                    menuIsClosing={menuIsClosing}
                    menuRef={menuRef}
                    navRefs={navRefs}
                    onCloseRef={onCloseRef}
                    onSearchToggle={onSearchToggle}
                    Search={Search}
                    biAction={biAction}
                    hideSearch={hideSearch}
                  />
                ) : null}
                {isMobile ? (
                  <ShapeShifter shiftShape={inView => setBaseButtonShape(inView ? 'Logo' : 'Menu')} />
                ) : null}
              </>
            )}
          </EventTracker>
        );
      }}
    </Query>
  );
}
