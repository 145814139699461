// @flow
import type { ChildrenArray, Node, } from 'react';
import usePreview from '../../hooks/Page/usePreview';

import useScripts from '../../hooks/Page/useScripts';

type Props = {
  children: ChildrenArray<Node> | Node,
  scriptTag: string,
};

function Scripts({ children, scriptTag, }: Props): Node {
  const { isPreview, } = usePreview();
  const scripts = useScripts();

  if (isPreview) {
    return null;
  }

  const { scriptsToRender, } = scripts || {};

  if (scriptsToRender && scriptsToRender.includes(scriptTag)) {
    return children;
  }

  return null;
}

export default Scripts;
