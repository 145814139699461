// @flow
import { useQuery, } from 'react-apollo';
import { HEADER_ARTICLE_QUERY, } from '@haaretz/graphql';
import format from 'date-fns/format';
import useArticleId from './useArticleId';
import type { ArticleHeaderType, } from '../../flowTypes/SeoDataType';
import useLiveBlogQueryVariables from './useLiveBlogQueryVariables';

const MACHINE_READABLE_FORMAT = "yyyy-MM-dd'T'HH:mm:ssxxx";

export default function useArticleHeaderData(): ArticleHeaderType | null {
  const articleId = useArticleId();

  const queryVariables = useLiveBlogQueryVariables(articleId);

  const { data, loading, error, } = useQuery(HEADER_ARTICLE_QUERY, {
    fetchPolicy: 'cache-first',
    variables: queryVariables,
    skip: !articleId,
  });

  if (loading) return null;
  if (error) {
    console.error('Header Article error', error);
    return null;
  }

  if (!data) return null;

  const header = data?.Page?.slots?.article || {};

  const { dateModifiedISO, datePublishedISO, } = header;

  const articleHeaderData: any = {
    ...header,
    modDate: dateModifiedISO
      ? format(new Date(dateModifiedISO), MACHINE_READABLE_FORMAT) : undefined,
    pubDate: datePublishedISO
      ? format(new Date(datePublishedISO), MACHINE_READABLE_FORMAT) : undefined,
  };

  return articleHeaderData;
}
