// @flow
import * as React from 'react';
import Section from '../AutoLevels/Section';
import H from '../AutoLevels/H';
import MenuSection from './MobileMenuSection';
import TopLinks from './MobileMenuTopLinks';
import HtzLink from '../HtzLink/HtzLink';
import Button from '../Button/Button';
import type { MenuItemType, MainMenuType, } from './MenuItemType';

type htmlRefType = { current: null | HTMLElement, };

type biActionType = ({ actionCode: number, }) => void;

type MobileNavigationListProps = {
  menu: MainMenuType,
  suggestedSections: ?MenuItemType[],
  giftPromotions: ?MenuItemType[],
  isHomepage: boolean,
  isLoggedIn: boolean,
  showFirstPromotion: boolean,
  navRefs: [ htmlRefType, htmlRefType, ],
  onCloseRef: { current: null | () => void, },
  biAction: ?biActionType,
  css: (Object | (Object => Object)) => string,
  theme: Object,
};

type SitesAndPromotionsProps = {
  sites: ?MenuItemType[],
  promotions: ?MenuItemType[],
  linkClassName: string,
  css: (Object | (Object => Object)) => string,
  setNavBiAction: ({
    name: string,
    isPersonal?: boolean,
    isMarketing?: boolean,
    featureType?: ?string,
  }) => ?biActionType,
};

type DividerProps = {
  css: (Object | (Object => Object)) => string,
  miscStyles?: ?Object,
};

const navListWrapperStyle = (({ theme, }) => ({
  gridArea: '2 / 1 / 4 / 4',
  overflowY: 'scroll',
  paddingTop: 'calc(8vw - 1rem)',
  paddingInlineEnd: '2rem',
  paddingInlineStart: '2rem',
  color: theme.color('mobileNav', 'sectionLink'),
  '-webkit-overflow-scrolling': 'touch',
  '&>:last-child': {
    marginBottom: '5rem',
  },
}));

Divider.defaultProps = { miscStyles: null, };
function Divider({ css, miscStyles, }: DividerProps): React.Node {
  return (
    <div
      className={css(({ theme, }) => ({
        borderBottom: `1px solid ${theme.color('mobileNav', 'divider')}`,
        width: '27rem',
        marginBottom: '1rem',
        ...(miscStyles || {}),
      }))}
      aria-hidden
    />
  );
}

function SitesAndPromotions({
  sites,
  promotions,
  linkClassName,
  setNavBiAction,
  css,
}: SitesAndPromotionsProps): React.Node {
  return sites || promotions ? (
    <React.Fragment>
      <Divider css={css} miscStyles={{ marginTop: '4rem', marginBottom: '4rem', }} />
      <ul
        data-google-interstitial={false}
      >
        {sites && sites.map(site => {
          const isCommercial = !!site.url.includes('labels');
          return (
            <li
              key={site.name}
              className={css(({ theme, }) => ({
                // marginTop: '1rem',
                color: theme.color('mobileNav', 'siteLink'),
                ...isCommercial
                  ? { color: theme.color('mobileNav', 'labelText'),
                    backgroundColor: theme.color('mobileNav', 'labelBg'), } : null,
              }))}
            >
              <HtzLink
                className={linkClassName}
                href={site.url}
                onClick={setNavBiAction({ name: site.name, })}
              >
                {site.name}
              </HtzLink>
            </li>
          );
        })}
      </ul>
    </React.Fragment>
  ) : null;
}

export default function MobileNavigationList({
  menu,
  suggestedSections,
  isHomepage,
  isLoggedIn,
  showFirstPromotion,
  giftPromotions,
  navRefs: [ mainNavRef, sectionNavRef, ],
  onCloseRef,
  biAction,
  css,
  theme,
}: MobileNavigationListProps): React.Node {
  const [ fullSubMenuName, setFullSubMenuName, ] = React.useState(null);
  const items = menu.items.filter(item => item.name !== theme.mobileNavigationMenuI18n.siteName);
  const fullSubMenu = fullSubMenuName ? items.find(item => item.name === fullSubMenuName) : null;
  const wrapperBaseClassName = css(navListWrapperStyle);

  const setNavBiAction = ({
    name,
    isMarketing = false,
    isPersonal = false,
    featureType = null,
  } = {}) => (biAction
    ? isMarketing
      ? () => biAction({
        actionCode: 3,
        feature: 'Navigation',
        featureType: 'Marketing',
        campaignName: name,
        campaignDetails: name,
      })
      : () => biAction({
        actionCode: 133,
        featureType: featureType || 'Content',
        campaignDetails: name,
        ...(isPersonal ? {
          feature: 'personal content',
          campaignName: 'personal section in navigation',
        } : {}),
      })
    : null
  );

  React.useEffect(() => {
    /* eslint-disable no-param-reassign */
    onCloseRef.current = () => {
      setFullSubMenuName(null);
      if (mainNavRef.current) mainNavRef.current.scrollTop = 0;
    };
    /* eslint-enable no-param-reassign */
  }, [ onCloseRef, mainNavRef, ]);

  const linkClassName = css({
    fontFamily: theme.fontStacks[theme.mastheadStyle.fontStack],
    paddingTop: '1rem',
    paddingBottom: '1rem',
    display: 'block',
    width: 'fit-content',
    minWidth: '6rem',
    minHeight: '6rem',
  });

  const promotions = showFirstPromotion
    ? menu.promotions
    : Array.isArray(menu.promotions) ? menu.promotions.slice(1) : null;

  return (
    <Section isFragment>
      <nav
        ref={mainNavRef}
        className={`${wrapperBaseClassName} ${css({
          pointerEvents: fullSubMenu ? 'none' : null,
          opacity: fullSubMenu ? 0 : 1,
          transition: 'opacity 0.5s',
          ...theme.type(1),
        })}`}
        aria-hidden={fullSubMenu}
      >
        {Array.isArray(giftPromotions) && giftPromotions.length
          ? (
            <div className={css({ marginBottom: '1rem', })}>
              { giftPromotions.map(promotion => (
                <HtzLink
                  className={css({
                    color: '#ed8600',
                    ':visited': {
                      color: '#ed8600',
                    },
                    ...theme.type(1),
                    fontWeight: '700',

                  })}
                  target="_blank"
                  href={promotion.url}
                  onClick={() => biAction({
                    actionCode: 49,
                    feature: 'Mobile Navigation',
                    featureType: 'Marketing',
                    campaignName: 'HDC Gift subscription button',
                  })}
                >
                  {promotion.name}
                </HtzLink>
              ))}
            </div>
          ) : null}
        <TopLinks
          isHomepage={isHomepage}
          isLoggedIn={isLoggedIn}
          suggestedSections={suggestedSections}
          setNavBiAction={setNavBiAction}
          linkClassName={linkClassName}
          css={css}
          theme={theme}
        />
        <H
          className={css({
            color: theme.color('secondary', 'base'),
            marginTop: '5rem',
            ...theme.type(0),
          })}
        >
          {theme.mobileNavigationMenuI18n.sectionsTitle}
        </H>
        <Divider css={css} miscStyles={{ borderBottom: `1px solid ${theme.color('secondary', 'base')}`, }} />
        <Section tagName="ul">
          {items.map(item => (
            <MenuSection
              section={item}
              key={item.name}
              linkClassName={linkClassName}
              css={css}
              theme={theme}
              tag="li"
              setFullSubMenuName={setFullSubMenuName}
              biAction={biAction}
              setNavBiAction={setNavBiAction}
            />
          ))}
        </Section>
        <SitesAndPromotions
          linkClassName={linkClassName}
          sites={menu.sites}
          promotions={promotions}
          setNavBiAction={setNavBiAction}
          css={css}
        />
      </nav>
      <nav
        ref={sectionNavRef}
        className={`${wrapperBaseClassName} ${css({
          zIndex: fullSubMenu ? 0 : -1,
          opacity: fullSubMenu ? 1 : 0,
          transition: 'opacity 0.3s 0.3s',
          ...theme.type(1),
        })}`}
        aria-hidden={!fullSubMenu}
      >
        {fullSubMenu && (
          <MenuSection
            section={fullSubMenu}
            linkClassName={linkClassName}
            css={css}
            theme={theme}
            tag="div"
            showAll
            setFullSubMenuName={setFullSubMenuName}
            biAction={biAction}
            setNavBiAction={setNavBiAction}
          />
        )}
      </nav>
    </Section>
  );
}
