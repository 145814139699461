// @flow

/* global window */
import * as React from 'react';
import { type StyleProps, } from '@haaretz/htz-css-tools';
import { useFela, } from 'react-fela';
import dynamic from 'next/dynamic';
import MastheadSearchButton from './SearchButton';
import MobileSearchButton from './MobileSearchButton';
import A11yDialog from '../../A11yDialog/A11yDialog';
import useQueryParam from '../../../hooks/useQueryParam';
import EventTracker from '../../../utils/EventTracker';

type MastheadSearchPropsType = {
  buttonMiscStyles: ?StyleProps,
  isMobile: boolean,
  showText: ?boolean,
  rippleColor: ?string,
  themeColor: ?string,
  searchComponent: ?React.ElementType,
  onClose: ?(boolean => void),
  onOpen: ?(boolean => void),
};

const dialogContainerStyle = ({ theme, }) => ({
  height: '100%',
  width: '100%',
  backgroundColor: theme.color('searchEngine', 'searchBg'),
  zIndex: theme.getZIndex('modal', 10),
});

const DefaultSearchEngine = dynamic(import('./SearchEngine'), { loading: () => null, ssr: false, });

const SEARCH_MODAL_ID = 'searchDialog';
function MastheadSearch(props: MastheadSearchPropsType) {
  const [ urlParamDialogState, ] = useQueryParam(SEARCH_MODAL_ID);
  const [ isActive, setIsActive, ] = React.useState(urlParamDialogState === 'open');
  const searchButtonRef: { current: ?HTMLButtonElement, } = React.useRef();
  const inputRef: { current: ?HTMLInputElement, } = React.useRef();
  const { theme, } = useFela();
  const dialogContainerMiscStyles = React.useMemo(() => dialogContainerStyle({ theme, }), [ theme, ]);

  const SearchEngine = props.searchComponent || DefaultSearchEngine;

  const close = () => {
    setIsActive(false);
    props.onClose && props.onClose(false);
    searchButtonRef.current && searchButtonRef.current.focus();
  };

  const open = () => {
    setIsActive(true);
    props.onOpen && props.onOpen(true);
  };

  const onOpen = ({ biAction, }) => {
    biAction && biAction({
      actionCode: 143,
      feature: 'Search from Header',
      featureType: 'Content',
      pageType: 'Search',
    });
  };

  const SearchButton = props.isMobile ? MobileSearchButton : MastheadSearchButton;

  return (
    <EventTracker>
      {({ biAction, }) => (
        <React.Fragment>
          <SearchButton
            ref={searchButtonRef}
            isActive={isActive}
            onClick={open}
            miscStyles={props.buttonMiscStyles}
            showText={props.showText}
            rippleColor={props.rippleColor}
            themeColor={props.themeColor}
          />
          <A11yDialog
            appendTo="modalsRoot"
            elementToHide="pageRoot"
            closeOnOutsideClick
            isModal
            modalId={SEARCH_MODAL_ID}
            isVisible={isActive}
            onClose={close}
            onOpen={() => onOpen({ biAction, })}
            overlayBgColor={theme.color('secondary')}
            containerMiscStyles={dialogContainerMiscStyles}
            focusableEl={inputRef}
            render={({ isVisible, handleClose, }) => (
              isVisible ? <SearchEngine onClose={handleClose} searchInputRef={inputRef} /> : null
            )}
          />
        </React.Fragment>
      )}
    </EventTracker>
  );
}

MastheadSearch.defaultProps = {
  buttonMiscStyles: null,
  isMobile: false,
  showText: null,
  rippleColor: null,
  searchComponent: null,
  onClose: null,
  onOpen: null,
  themeColor: 'defaultTheme',
};

export default MastheadSearch;
