/**
 * serialize an object to query-string format.
 *
 * @param {Object} params Object to serialize
 */
export default function toQueryString(params = {}) {
  return Object.keys(params)
    .filter(key => !!params[key])
    .map(key => {
      const val = params[key] instanceof Object
        ? JSON.stringify(params[key])
        : params[key];
      return `${key}=${val}`;
    })
    .join('&');
}
