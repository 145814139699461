import React from 'react';
import ApolloConsumer from '../ApolloBoundary/ApolloConsumer';
import NoSSR from '../NoSSR/NoSSR';
import useMedia from '../../hooks/useMedia';
import useWebViewChecker from '../../hooks/useWebViewChecker';

export default function DeviceTypeInjector() {
  return (
    <div>
      <NoSSR>
        <DeviceTypeSetter />
      </NoSSR>
    </div>
  );
}

function DeviceTypeSetter() {
  const matches = useMedia({ query: { until: 's', }, matchOnServer: false, });
  const isWebView = useWebViewChecker();

  return (
    <ApolloConsumer>
      {client => {
        client.writeData({
          data: { platform: isWebView ? 'app' : matches ? 'mobile' : 'web', },
        });

        return null;
      }}
    </ApolloConsumer>
  );
}
