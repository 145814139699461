// @flow

import * as React from 'react';
import { useFela, } from 'react-fela';
import type { ColorPropType, } from '../../flowTypes/ColorPropType';

// import useScrollYPosition from '../../hooks/useScrollYPosition';
import ArticlePageMasthead from './ArticlePageMasthead';
import HomePageMasthead from './HomePageMasthead';
import MobileNavigation from '../MobileNavigationMenu/MobileNavigation';
import WebViewExclude from '../WebViewExclude/WebViewExclude';


type MastheadProps = {
  pageType: string,
  contentId?: string,
  searchComponent?: {
    mobile?: React.ElementType,
    desktop?: React.ElementType,
  },
  hideSearch: ?boolean,
  rowBgc: ?ColorPropType,
  containerBgc: ?ColorPropType,
  menuItems: ?Object,
  forceStaticPosition: ?boolean,
};

Masthead.defaultProps = {
  rowBgc: null,
  containerBgc: null,
  searchComponent: null,
  hideSearch: false,
  contentId: null,
  menuItems: null,
  forceStaticPosition: false,
};

export default function Masthead(props: MastheadProps): React.Node {
  const { contentId, menuItems, searchComponent, } = props;
  const SearchDesktop = searchComponent ? searchComponent.desktop || null : null;
  const SearchMobile = searchComponent ? searchComponent.mobile || null : null;

  return (
    <WebViewExclude>
      <MastheadComponent {...props} Search={SearchDesktop} />
      <BottomMobileNav
        contentId={contentId}
        menuItems={menuItems?.[1]}
        Search={SearchMobile}
        pageType={props.pageType}
        hideSearch={props.hideSearch}
      />
    </WebViewExclude>
  );
}

const MastheadComponent = React.memo(
  // eslint-disable-next-line react/prop-types
  ({ pageType, Search, hideSearch, ...props }) => ([ 'homepage', 'finance', ].includes(pageType) ? (
    <HomePageMasthead
      disableHomepageLink={pageType === 'homepage'}
      Search={Search}
      hideSearch={hideSearch}
      {...props}
    />
  ) : (
    <ArticlePageMasthead Search={Search} {...props} />
  ))
);

// eslint-disable-next-line react/no-multi-comp
export const BottomMobileNav = React.memo(
  // eslint-disable-next-line react/prop-types
  ({ contentId, menuItems, Search, pageType, hideSearch, }): React.Node => {
    const { css, theme, } = useFela();
    const className = css({
      ...theme.mq({ from: 's', }, { display: 'none', }),
    });

    return (
      <div className={className}>
        <MobileNavigation
          contentId={contentId}
          menuItems={menuItems}
          Search={Search}
          hideSearch={hideSearch}
          pageTypeOverwrite={pageType}
        />
      </div>
    );
  }
);
