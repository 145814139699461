// @flow
import config from 'config';
import useEnvironment from './useEnvironment';

type DomainType = 'haaretz.co.il' | 'themarker.com' | 'haaretz.com';

const allowedDomains = [ 'haaretz.co.il', 'themarker.com', 'haaretz.com', ];

const prodSubDomains = [ 'www', 'login', 'promotions', 'finance', ];
const stageSubDomainPrefix = /(react|login|promotions|finance)-/;

function useAppURL(appSubDomain: string, path?: string, domainOverride?: DomainType): string {
  const env = useEnvironment();
  if (typeof appSubDomain !== 'string' || !appSubDomain) {
    throw new Error('Parameter appSubDomain is required or isn\'t type string');
  }

  const domain: DomainType = domainOverride
    && typeof domainOverride === 'string'
    ? (allowedDomains.find(x => (domainOverride || '').includes(x)) || 'haaretz.co.il')
    : config.has('domain')
      ? config.get('domain')
      : 'haaretz.co.il';
  const hostname: string = config.has('hostname') ? config.get('hostname') : 'www';
  const isSSL = config.get('useSSL');

  const protocol = isSSL === 'true' || isSSL === true || hostname === 'www' ? 'https' : 'http';

  let subDomain = appSubDomain;
  if (!prodSubDomains.includes(hostname)) {
    if (env === 'dev') {
      subDomain = `${appSubDomain}-dev1.k8s-stage`;
    }
    else if (stageSubDomainPrefix.test(hostname)) {
      const subDomainPrefixToReplace = env === 'prod' && subDomain === 'react' ? '' : `${appSubDomain}-`;
      subDomain = hostname.replace(stageSubDomainPrefix, subDomainPrefixToReplace);
    }
    else {
      subDomain = `${appSubDomain}-${hostname}`;
    }
  }
  else if (subDomain === 'react') {
    subDomain = 'www';
  }

  if (env === 'stage' || env === 'preProd') {
    subDomain += '.k8s-stage';
  }

  const absolutePath = typeof path === 'string' && path.length > 1 ? !path.startsWith('/') ? `/${path}` : path : '';
  return `${protocol}://${subDomain}.${domain}${absolutePath}`;
}

export default useAppURL;
