// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import {
  parseComponentProp,
  parseStyleProps,
  type StyleProps,
  type ComponentPropResponsiveObject,
} from '@haaretz/htz-css-tools';

import LogoAndDate from './LogoAndDate';
import { styles, } from '../PageLayout/LayoutContainer';
import type { ColorPropType, } from '../../flowTypes/ColorPropType';

const { useState, } = React;

// ////////////////////////////////////////////////////////////////// //
//                               Types                                //
// ////////////////////////////////////////////////////////////////// //

type RenderFunc = (() => void, () => void) => React.Node;
type SidesEnum = 'start' | 'end' | 'bottom' | 'sides' | 'all';

type MastheadWrapperProps = {
  /** the color of the layout container */
  backgroundColor: ?ColorPropType,
  /** A list of panels to not render */
  disablePanels: ?SidesEnum | SidesEnum[],
  /** Don't render date-time element */
  disableDatetime: ?boolean,
  /* stretch masthead over full width of page */
  isFullWidth: boolean,
  /** configures hiding of panels in different break-points. */
  panelsResponsiveHiding: ?(ComponentPropResponsiveObject<SidesEnum>[]),
  /**
   * Render function for the 'Start Panel'.
   * The function will provided with two toggle functions: toggleOpposite, toggleSelf
   * (toggleOpposite, toggleSelf) => {....JSX...}
   */
  renderStartPanel: ?(() => void, () => void) => React.Node,
  /**
   * Render function for the 'End Panel'.
   * The function will provided with two toggle functions: toggleOpposite, toggleSelf
   * (toggleOpposite, toggleSelf) => {....JSX...}
   */
  renderEndPanel: ?(() => void, () => void) => React.Node,
  /** Render function for the 'Bottom Panel'. */
  renderBottomPanel: ?() => React.Node,
  /** Misc styles for the wrapper component */
  miscStyles: ?StyleProps,
  /** Misc styles for the logo component */
  datetimeMiscStyles: ?StyleProps,
  isHomepageVariation: ?boolean,
  disableHomepageLink: ?boolean,
  themeColor: ?string,
};

// ////////////////////////////////////////////////////////////////// //
//                               Style                                //
// ////////////////////////////////////////////////////////////////// //

function fullWidthWrapperStyle({ theme, miscStyles, }) {
  return {
    extend: [ ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []), ],
  };
}

function logoWrapperStyle({ theme, showLogo, }) {
  return {
    visibility: showLogo ? 'visible' : 'hidden',
    opacity: showLogo ? 1 : 0,
    marginTop: '1rem',
    paddingTop: '2rem',
    paddingBottom: '2rem',
    extend: [
      theme.getTransition(1),
      theme.mq({ until: 's', }, { paddingBottom: '1rem', paddingTop: '1rem', }),
    ],
  };
}

function panelStyle({ theme, isFullWidth, panelName, panelsResponsiveHiding, isHomepageVariation, }) {
  return {
    ...(isHomepageVariation
      ? {}
      : {
        height: '100%',
      }),
    position: 'absolute',
    // bottom: 0,
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'row',
    width: isFullWidth ? '100%' : null,
    extend: [
      ...(isHomepageVariation ? [ theme.mq({ from: 's', }, { top: 0, }), ] : []),
      parseComponentProp('display', panelsResponsiveHiding, theme.mq, panelVisibility(panelName)),
    ],
  };
}

function startPanelStyle({ theme, showEndPanel, panelsResponsiveHiding, isHomepageVariation, }) {
  return {
    ...panelStyle({
      theme,
      isFullWidth: !showEndPanel,
      panelName: 'start',
      panelsResponsiveHiding,
      isHomepageVariation,
    }),
    insetInlineStart: 0,
  };
}

function endPanelStyle({ theme, showStartPanel, panelsResponsiveHiding, isHomepageVariation, }) {
  return {
    ...panelStyle({
      theme,
      isFullWidth: !showStartPanel,
      panelName: 'end',
      panelsResponsiveHiding,
      isHomepageVariation,
    }),
    insetInlineEnd: 0,
  };
}

function bottomPanelStyle({ theme, panelsResponsiveHiding, }) {
  return {
    extend: [
      parseComponentProp('display', panelsResponsiveHiding, theme.mq, panelVisibility('bottom')),
    ],
  };
}

function panelVisibility(panelName) {
  return function setVisibility(prop, hiddenPanels) {
    return hiddenPanels && isPanelMatched(panelName, hiddenPanels)
      ? { display: 'none', }
      : { display: 'flex', };
  };
}

MastheadWrapper.defaultProps = {
  backgroundColor: null,
  datetimeMiscStyles: null,
  disableDatetime: false,
  disablePanels: null,
  isFullWidth: false,
  miscStyles: null,
  panelsResponsiveHiding: null,
  renderBottomPanel: null,
  renderEndPanel: null,
  renderStartPanel: null,
  isHomepageVariation: false,
  disableHomepageLink: false,
  themeColor: 'defaultTheme',
};
export default function MastheadWrapper({
  backgroundColor,
  datetimeMiscStyles,
  disableDatetime,
  disablePanels,
  isFullWidth,
  miscStyles,
  panelsResponsiveHiding,
  renderBottomPanel,
  renderEndPanel,
  isHomepageVariation,
  disableHomepageLink,
  themeColor,
  renderStartPanel,
}: MastheadWrapperProps): React.Node {
  const [ showStartPanel, setShowStartPanel, ] = useState(isPanelEnabled('start', disablePanels));
  const [ showEndPanel, setShowEndPanel, ] = useState(isPanelEnabled('end', disablePanels));
  const showBottomPanel = isPanelEnabled('bottom', disablePanels);
  const showLogo = showStartPanel && showEndPanel;

  const { css, } = useFela({
    namedBgc: backgroundColor,
    miscStyles,
    showStartPanel,
    showEndPanel,
    showLogo,
    panelsResponsiveHiding,
    isHomepageVariation,
  });

  function internalRenderStartPanel(render: RenderFunc): React.Node {
    const toggleOpposite = toggleEndPanelVisibility;
    const toggleSelf = toggleStartPanelVisibility;

    return showStartPanel ? (
      <div className={css(startPanelStyle)}>{render(toggleOpposite, toggleSelf)}</div>
    ) : null;
  }

  function internalRenderEndPanel(render: RenderFunc): React.Node {
    const toggleOpposite = toggleStartPanelVisibility;
    const toggleSelf = toggleEndPanelVisibility;

    return showEndPanel ? (
      <div className={css(endPanelStyle)}>{render(toggleOpposite, toggleSelf)}</div>
    ) : null;
  }

  function internalRenderBottomPanel(render: () => React.Node): React.Node {
    return showBottomPanel ? <div className={css(bottomPanelStyle)}>{render()}</div> : null;
  }

  function togglePanelVisibility(panelName: 'start' | 'end', visibility: boolean) {
    panelName === 'start' ? setShowStartPanel(visibility) : setShowEndPanel(visibility);
  }

  function toggleStartPanelVisibility() {
    togglePanelVisibility('start', !showStartPanel);
  }

  function toggleEndPanelVisibility() {
    togglePanelVisibility('end', !showEndPanel);
  }
  return (
    <div data-test="masthead" className={css(isFullWidth ? fullWidthWrapperStyle : styles)}>
      {renderStartPanel && showStartPanel ? internalRenderStartPanel(renderStartPanel) : null}
      <div className={css(logoWrapperStyle)}>
        <LogoAndDate
          disableLink={disableHomepageLink}
          disableDatetime={disableDatetime}
          themeColor={themeColor}
          datetimeMiscStyles={{
            display: [ { until: 'l', value: 'none', }, ],
            ...datetimeMiscStyles,
          }}
          tabIndex={showLogo ? 0 : -1}
        />
      </div>
      {renderEndPanel && showEndPanel ? internalRenderEndPanel(renderEndPanel) : null}

      {renderBottomPanel ? internalRenderBottomPanel(renderBottomPanel) : null}
    </div>
  );
}

// ////////////////////////////////////////////////////////////////// //
//                          Helper Functions                          //
// ////////////////////////////////////////////////////////////////// //

function isPanelMatched(panelName: SidesEnum, optionName): boolean {
  if (!optionName) return false;

  const disablePanels = Array.isArray(optionName) ? optionName : [ optionName, ];

  return disablePanels.reduce((result, settings) => {
    if (result) return result;

    switch (panelName) {
      case 'start':
        return /start|sides|all/.test(settings);
      case 'end':
        return /end|sides|all/.test(settings);
      case 'bottom':
        return /bottom|all/.test(settings);
      default:
        return false;
    }
  }, false);
}

function isPanelEnabled(panel: SidesEnum, disablePanels: ?SidesEnum | SidesEnum[]): boolean {
  return !disablePanels || !isPanelMatched(panel, disablePanels);
}
