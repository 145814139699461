// @flow

import { useQuery, } from 'react-apollo';
import { SEO_QUERY, } from '@haaretz/graphql';
import { useRouter, } from 'next/router';
import useArticleId from './useArticleId';
import useAuthorsData from './useAuthorsData';
import useArticleType from './useArticleType';
import useArticleHeaderData from './useArticleHeaderData';
import usePreview from './usePreview';
import useAuthorId from './useAuthorId';
import useTagId from './useTagId';
import type { SeoDataType, } from '../../flowTypes/SeoDataType';

export default function useSEOData(): SeoDataType | null {
  const articleId = useArticleId();
  const { previewId, } = usePreview();
  const authorId = useAuthorId();
  const tagId = useTagId();
  const router = useRouter();

  const path = router.query.path;

  const id = articleId || authorId || tagId || previewId;

  const { data, loading, error, } = useQuery(SEO_QUERY, {
    fetchPolicy: 'cache-first',
    variables: id ? {
      id,
    } : { path, },
  });

  const authors = useAuthorsData();
  const articleType = useArticleType();
  const articleHeader = useArticleHeaderData();

  if (loading) return null;
  if (error) {
    console.error('Breadcrumbs error', error);
    return null;
  }

  if (!data) return null;
  // {
  //   ...seoData,
  //   ...header,
  //   author: authors?.[0],
  //   articleType,
  //   firstParagraph: articleData?.firstParagraph,
  //   isLockedPush: articleData?.isLockedPush,
  //   isPremiumContent,
  // }

  const {
    metaDescription: description,
    metaKeywords: keywords,
    metaTitle: title,
  } = data.Page?.seoData || {};

  const seoData: any = {
    isPremiumContent: null, // TODO: not exist
    isLockedPush: null, // TODO: not exist
    firstParagraph: null, // TODO: not exist
    author: (authors || [])[0],
    header: articleHeader,
    ...(articleHeader || {}),
    ...(data.Page?.seoData || {}),
    articleType,
    description,
    keywords,
    title,
  };

  return seoData;
}
