// @flow
import React, { type Node, type ChildrenArray, } from 'react';
import { borderTop, borderEnd, } from '@haaretz/htz-css-tools';
import MenuLogo from './MenuLogo';
import VisuallyHidden from '../VisuallyHidden/VisuallyHidden';

type NavBarProps = {
  toggleMenu: (?{ onOpen?: () => void, onClose?: () => void, }) => void,
  buttonShape: "Logo" | "Menu" | "Close",
  menuIsOpen: boolean,
  theme: Object,
  css: (Object | Function) => string,
  biAction: ?({ actionCode: number, additionalInfo?: Object, }) => void,
  children: ?ChildrenArray<Node>,
};

const bottomBarBackdrop = ({ theme, }) => ({
  position: 'fixed',
  bottom: '0',
  right: '0',
  width: '100%',
  paddingBottom: 'env(safe-area-inset-bottom, 0)',
  height: [
    theme.mobileStyle.menuHeight,
    `calc(${theme.mobileStyle.menuHeight} + env(safe-area-inset-bottom, 0))`,
  ],
  zIndex: theme.getZIndex([ 'masthead', 'mobile', ], 2),
  backgroundColor: theme.color('white'),
});

const bottomBar = ({ theme, withShareBar, menuIsOpen, menuIsClosing, showPromotionButton, }) => ({
  bottom: '0',
  right: '0',
  width: '100%',
  paddingBottom: 'env(safe-area-inset-bottom, 0)',
  color: theme.color('mobileNav', 'barText'),
  fontWeight: 'bold',
  zIndex: theme.getZIndex([ 'masthead', 'mobile', ], 3),
  backgroundColor: theme.color('white'),
  transition: 'background-color 0.4s',
  ...borderTop(2, 0, 'solid', theme.color('mobileNav', 'barBorder')),
  // the next position rule has to come after the above '...borderTop' spread,
  // since we need to overwrite the "position: 'relative", that is included
  // in the 'borderTop' object.
  position: 'fixed',
  display: 'grid',
  gridTemplateAreas: `
  ".     button    ."
  "right button left"
  ".     button    ."
  `,
  gridTemplateColumns: '1fr auto 1fr',
  gridTemplateRows: '0 minmax(6rem, 1fr) 0',
  gridRowGap: '2rem',
  alignItems: 'center',
  textAlign: 'center',
  '-webkit-tap-highlight-color': 'transparent',
  ...(withShareBar
    ? {
      pointerEvents: menuIsOpen && !menuIsClosing ? null : 'none',
      backgroundColor:
        menuIsOpen && !menuIsClosing
          ? theme.color('white')
          : theme.color('transparent'),
    }
    : {}),
});

const buttonStyles = {
  pointerEvents: 'initial',
  gridArea: 'button',
  marginTop: '-5rem',
};

const sidesBaseStyles = ({ theme, menuIsOpen, menuIsClosing, }) => ({
  opacity: menuIsOpen && !menuIsClosing ? 0 : 1,
  pointerEvents: menuIsOpen && !menuIsClosing ? 'none' : null,
  transition: 'opacity 0.4s',
  display: 'grid',
  gridAutoFlow: 'column',
  gridAutoColumns: '1fr',
  minHeight: '6rem',
  '& > *:not(:last-child)': {
    ...borderEnd(1, 'solid', theme.color('neutral', '-6')),
  },
});

export default function NavBar({
  toggleMenu,
  buttonShape,
  menuIsOpen,
  css,
  theme,
  biAction,
  children,
}: NavBarProps): Node {
  const sidesBaseClassName = css(sidesBaseStyles);
  const childrenArray = children ? React.Children.toArray(children) : null;
  const rightSide = childrenArray && childrenArray.slice(0, childrenArray.length / 2);
  const leftSide = childrenArray && childrenArray.slice(childrenArray.length / 2);

  return (
    <>
      <div className={css(bottomBarBackdrop)} />
      <div className={css(bottomBar)} data-test="navBar-mobile">
        <button
          type="button"
          className={css(buttonStyles)}
          data-test="navBar-mobile-menuButton"
          onClick={
            biAction
              ? () => {
                toggleMenu({
                  onOpen: () => biAction({ actionCode: 132, }),
                  onClose: () => biAction({ actionCode: 146, }),
                });
              }
              : toggleMenu
          }
        >
          {/* this cancels focus indication upon click whilst still allowing it upon tabbing */}
          <div
            aria-hidden
            tabIndex={-1}
            className={css({
              outline: 'none',
              ':-moz-focusring': { outline: 'none', },
            })}
          >
            <MenuLogo requestedShape={buttonShape} />
          </div>
          <VisuallyHidden>
            {theme.mobileNavigationMenuI18n[menuIsOpen ? 'close' : 'open']}
          </VisuallyHidden>
        </button>
        {childrenArray && (
          <>
            <div
              className={`${sidesBaseClassName} ${css(({ withShareBar, showPromotionButton, }) => ({
                gridArea: theme.direction === 'ltr' ? 'left' : 'right',
                opacity: showPromotionButton ? '1 !important' : undefined,
              })
              )}`}
            >
              {rightSide}
            </div>
            <div
              className={`${sidesBaseClassName} ${css({
                gridArea: theme.direction === 'ltr' ? 'right' : 'left',
              })}`}
            >
              {leftSide}
            </div>
          </>
        )}
      </div>
    </>
  );
}
