/* global document localStorage */
import { LAST_MAIN_SECTIONS_KEY, LAST_ALL_SECTIONS_KEY, } from '../LastArticlesUpdate/LastArticlesUpdate';

export function disablePageScroll(isOn) {
  if (typeof document !== 'undefined') {
    const html = document.documentElement;
    html.style.overflow = isOn ? 'hidden' : null;
    // todo: remove the touchAction disabling once the backdrop scrolling issue is fully resolved
    html.style.touchAction = isOn ? 'none' : null;
  }
  return null;
}

const uniqueBy = compareBy => {
  const testSet = new Set();
  return item => {
    if (testSet.has(item[compareBy])) return false;
    testSet.add(item[compareBy]);
    return true;
  };
};

const getTopTwoSections = (lastSections, suggestedSections) => {
  const suggestedSectionsById = suggestedSections.reduce(
    (byId, section) => byId.set(section.contentId, section), new Map()
  );
  const topTwo = [];
  for (let i = 0; i < lastSections.length; i++) {
    if (suggestedSectionsById.has(lastSections[i].contentId)) topTwo.push(lastSections[i]);
    if (topTwo.length === 2) break;
  }

  return topTwo;
};

export function getSectionsToDisplay(suggestedSections) {
  const lastMainSections = JSON.parse(localStorage.getItem(LAST_MAIN_SECTIONS_KEY)) || [];
  const lastAllSections = JSON.parse(localStorage.getItem(LAST_ALL_SECTIONS_KEY)) || [];

  const allPossibleSections = [
    ...getTopTwoSections(lastMainSections, suggestedSections),
    ...lastAllSections,
  ];
  return allPossibleSections
    .filter(uniqueBy('contentId'))
    .slice(0, 4);
}

const getUsername = (user, universities) => (
  user.firstName || null
);
const getGreeting = (hour, greetings) => {
  if (hour >= 5 && hour < 11) return greetings.morning || greetings.default;
  if (hour >= 11 && hour < 15) return greetings.noon || greetings.default;
  if (hour >= 15 && hour < 18) return greetings.afternoon || greetings.default;
  if (hour >= 18 && hour < 21) return greetings.evening || greetings.default;
  if (
    (hour >= 21 && hour < 24) || (hour >= 0 && hour < 5)
  ) return greetings.night || greetings.default;
  return greetings.default;
};
export function greetUser({ isLoggedIn, user, theme, }) {
  const hour = new Date().getHours();
  const greeting = getGreeting(hour, theme.greetingsI18n);
  const username = isLoggedIn && getUsername(user);
  const personalSuffix = username ? `, ${username}` : '';

  return greeting + personalSuffix;
}
