/* global document */
import React, { useCallback, useImperativeHandle, useRef, Fragment, } from 'react';
import PropTypes from 'prop-types';
import { useFela, } from 'react-fela';
import ReactGA from 'react-ga';
import DropdownList from '../DropdownList/DropdownList';
import Hamburger from '../Animations/Hamburger';
import Item from '../DropdownList/DropdownItem';
import Button from '../Button/Button';
import { dropdownItemStyle, dropdownListStyle, } from '../Masthead/mastheadDropdownListStyle';
import GET_USER_BY_MAIL from '../Account/queries/getUserByMail.graphql';
import Query from '../ApolloBoundary/Query';
import UserDispenser from '../User/UserDispenser';
import useMastheadDisplayed from '../Masthead/useMastheadDisplayed';
import EventTracker from '../../utils/EventTracker';
import checkSiteFromConfig from '../../utils/checkSiteFromConfig';


const { isHDC, } = checkSiteFromConfig();

const menuButtonStyle = ({ theme, isOpen, themeColor, }) => ({
  height: '100%',
  border: 'none',
  color: theme.color(themeColor, 'mastheadText'),
  display: 'flex',
  alignItems: 'center',
  fontWeight: '700',
  paddingRight: '1rem',
  paddingLeft: '1rem',
  outline: 'none',
  ':hover': {
    backgroundColor: theme.color('masthead', 'bgButtonHover'),
    color: theme.color('neutral', '-10'),
  },
  ':focus': {
    backgroundColor: theme.color('masthead', 'bgButtonHover'),
    color: theme.color('neutral', '-10'),
  },
  ...(isOpen
    ? {
      backgroundColor: theme.color('masthead', 'bgMenu'),
      color: theme.color('neutral', '-10'),
      ':focus': {
        backgroundColor: theme.color('masthead', 'bgMenu'),
      },
      ':focus:hover': {
        backgroundColor: theme.color('masthead', 'bgButtonHover'),
      },
    }
    : {}),
  extend: [ theme.type(-1, { lines: 6, }), theme.getTransition(1, 'swiftOut'), ],
});

const baseProp = {
  /**
   * The section's name to display.
   */
  name: PropTypes.string,
  /**
   * Section's destination.
   */
  url: PropTypes.string,
  /**
   * Section's pages (may contain pages or sub-sections with their own pages).
   */
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * The page's name to display.
       */
      name: PropTypes.string,
      /**
       * page's destination.
       */
      url: PropTypes.string,
      /**
       * page's sub-pages as described above.
       */
      pages: PropTypes.arrayOf(PropTypes.object),
    })
  ),
};

NavigationMenu.propTypes = {
  /**
   * An object of sections to be listed, each with a different styling.
   */
  menuSections: PropTypes.shape({
    /**
     * An array of main menu items.
     */
    items: PropTypes.arrayOf(PropTypes.shape(baseProp)),
    /**
     * An array of sites links.
     */
    sites: PropTypes.arrayOf(PropTypes.shape(baseProp)),
    /**
     * An array of promotion items.
     */
    promotions: PropTypes.arrayOf(PropTypes.shape(baseProp)),
  }).isRequired,
  /* User type from User dispenser in Masthead */
  userType: PropTypes.string,
  themeColor: PropTypes.string,
  userEmail: PropTypes.string,
};
NavigationMenu.defaultProps = { userType: 'anonymous', themeColor: 'defaultTheme', userEmail: null, };


const subscriptionNames = {
  274: 'BOTH',
  243: 'HTZ',
  273: 'TM',
  282: 'INACTIVE',
  239: 'HDC',
};

function NavigationMenu({ menuSections, themeColor, userType, userEmail, }) {
  const { theme, css, } = useFela();
  const mastheadDisplayed = useMastheadDisplayed();
  const { subscriptionGiftUrl, subscriptionGiftText, } = theme.mastheadSubscriptionI18n;

  const { items, sites, promotions, purchases, } = (menuSections?.items || []).reduce((res, item) => {
    if (item.kind === 'site') {
      res.sites.push(item);
    }
    else if (item.kind === 'commercial') {
      res.promotions.push(item);
    }
    else if (item.kind === 'purchase') {
      res.purchases.push(item);
    }
    else {
      res.items.push(item);
    }
    return res;
  }, { items: [], sites: [], promotions: [], purchases: [], });

  const giftPromotions = [];

  if (isHDC && userType === 'paying') {
    giftPromotions.push({
      name: subscriptionGiftText,
      url: subscriptionGiftUrl,
      isGift: true,
      __typename: 'NavigationLink',
      key: 'gift',
      inputTemplate: 'NavigationLink',
    });
  }

  const buttonRef = useRef(null);
  const onClose = useCallback(() => {
    if (document.activeElement !== document.body) return;
    buttonRef.current && buttonRef.current.focus();
  }, []);

  if (!menuSections) return null;
  return (
    <EventTracker>
      {({ biAction, }) => (
        <DropdownList
          mainMenuStyle={{ position: 'relative', alignSelf: 'stretch', }}
          onClose={onClose}
          render={({ renderButton, ListWrapper, isOpen, closeList, }) => {
            if (!mastheadDisplayed && isOpen) closeList();
            return (
              <Fragment>
                {renderButton(({ toggleState, }) => (
                  <MenuButton
                    biAction={biAction}
                    ref={buttonRef}
                    toggleState={toggleState}
                    isOpen={isOpen}
                    themeColor={themeColor}
                  />
                ))}
                {isOpen ? (
                  <Query
                    query={GET_USER_BY_MAIL}
                    skip={typeof document === 'undefined' || !userEmail}
                    variables={{
                      email: userEmail,
                    }}
                  >
                    {({ loading, error, data, refetch, }) => {
                      let isFullSubscription;
                      let userProductType;
                      let isFromAppStores;
                      if (error || loading) {
                        isFullSubscription = false;
                      }
                      else if (data?.userByMail) {
                        const { ...personalInfo } = data?.userByMail || {};

                        const userProduct = personalInfo?.products?.filter(product => {
                          if (product.prodNum === 274 && product.status === 'SUBSCRIBED') {
                            return true;
                          } if ((product.prodNum === 243 && product.status === 'SUBSCRIBED')
                          || (product.prodNum === 273 && product.status === 'SUBSCRIBED')) {
                            return true;
                          }
                          return null;
                        })?.[0];

                        const { prodNum, connectionType } = userProduct || {};
                         isFromAppStores = connectionType === 763 || connectionType === 776;

                        userProductType = (prodNum && subscriptionNames[prodNum]) || userType;
                        isFullSubscription = (userProductType === 'BOTH');
                      }

                      return (
                        <ListWrapper
                          listStyle={{
                            ...dropdownListStyle(theme),
                            minWidth: '29rem',
                          }}
                          itemStyle={dropdownItemStyle(theme)}
                          closeList={closeList}
                          dataTest="navigationMenuDesktop"
                        >
                          {[
                            ...(combinedItems(items, biAction, theme) || []),
                            ...(combinedGiftPromotions(giftPromotions, userType, biAction, theme, css) || []),
                            ...(isFromAppStores || isFullSubscription) ? [] : (combinedPurchases(purchases, userType, biAction, theme, userProductType) || []),
                            ...(combinedSites(sites, biAction, theme) || []),
                            ...(combinedPromotions(promotions, biAction, theme) || []),
                          ]}
                        </ListWrapper>
                      );
                    }}
                  </Query>
                ) : null}
              </Fragment>
            );
          }}
        />
      )}
    </EventTracker>
  );
}

// eslint-disable-next-line react/prop-types
export default function WrappedNavigationMenu({ contentId, menuItems, themeColor, }) {
  return (
    <UserDispenser
      render={({ user, }) => <NavigationMenu themeColor={themeColor} menuSections={menuItems} userType={user.type} userEmail={user.email} />}
    />
  );
}

function combinedItems(items, biAction, theme) {
  return (
    items
    && items.map(item => (
      <Item
        variant={theme.navMenuStyle.buttonVariant}
        key={`item ${item.name}`}
        {...item}
        onClick={
          biAction && item.url
            ? () => biAction({
              actionCode: 133,
              additionalInfo: {
                name: item.name,
              },
            })
            : null
        }
        miscStyles={{
          fontWeight: theme.mastheadStyle.fontWeight,
          backgroundColor: theme.color('masthead', 'bgMenu'),
          ':hover': {
            backgroundColor: theme.color('masthead', 'bgMenuHover'),
          },
          ':focus': {
            backgroundColor: theme.color('masthead', 'bgMenuHover'),
            borderStyle: theme.mastheadStyle.focusBorderStyle,
          },
          ':active': {
            backgroundColor: theme.color('masthead', 'bgMenuHover'),
          },
        }}
      />
    ))
  );
}

const combinedSitesStyle = ({ theme, }) => ({
  color: theme.color('masthead', 'textSiteLinks'),
  backgroundColor: theme.color('masthead', 'bgMenuSites'),
  textDecoration: 'underline',
  ':visited': {
    color: theme.color('masthead', 'textSiteLinks'),
  },
});

const combinedSitesLabelStyle = ({ theme, }) => ({
  color: theme.color('labelMasthead', 'textSiteLinks'),
  backgroundColor: theme.color('labelMasthead', 'bgMenuSites'),
  border: `1px solid ${theme.color('primary')}`,
  ':visited': {
    color: theme.color('labelMasthead', 'textSiteLinks'),
  },
});

function combinedSites(sites, biAction, theme) {
  return (
    sites && sites.map(site => {
      const isCommercial = !!site.url.includes('labels');

      return (
        <Item
          {...site}
          key={`site ${site.name}`}
          onClick={
          biAction && site.url
            ? () => biAction({
              actionCode: 133,
              additionalInfo: {
                name: site.name,
              },
            })
            : null
        }
          miscStyles={{
            ':hover': {
              backgroundColor: theme.color('masthead', 'bgMenuHover'),
              color: theme.color('masthead', 'textSiteLinksHover'),
            },
            ':focus': {
              backgroundColor: theme.color('masthead', 'bgMenuHover'),
              color: theme.color('masthead', 'textSiteLinksHover'),
              borderStyle: theme.mastheadStyle.focusBorderStyle,
            },
            ':active': {
              backgroundColor: theme.color('masthead', 'bgMenuHover'),
              color: theme.color('masthead', 'textSiteLinksHover'),
            },
            ...(isCommercial ? combinedSitesLabelStyle({ theme, }) : combinedSitesStyle({ theme, })),
          }}
          name={site.name}
          url={site.url}
          attrs={{ 'data-google-interstitial': false, }}
        />
      );
    })
  );
}


function combinedPurchases(purchases, userType, biAction, theme, userProductType) {
  const userProductInfo = userProductType === 'TM' ? 'paying_tm'
    : userProductType === 'HTZ' ? 'paying_htz' : userType;

  return (
    purchases && purchases.map(purchase => (
      (purchase.includeUserTypes.includes(userProductInfo)) ? (
        <Item
          key={`purchase ${purchase.name}`}
          onClick={
                  biAction && purchase.url
                    ? () => {
                      biAction({
                        actionCode: 3,
                        feature: 'Navigation',
                        featureType: 'Marketing',
                        campaignName:
                        purchase.includeUserTypes.includes('paying_tm')
                        || purchase.includeUserTypes.includes('paying_htz') ? 'upsell' : 'subscription',
                        campaignDetails: 'navigation_default',
                      });
                    }
                    : null
              }
          variant="salesOpaque"
          miscStyles={{
            ':focus': {
              borderStyle: theme.mastheadStyle.focusBorderStyle,
            },
          }}
          name={purchase.name}
          url={purchase.url}
          attrs={{ 'data-google-interstitial': false, }}
        />
      )
        : []
    ))
  );
}


function combinedPromotions(promotions, biAction, theme) {
  return (
    promotions
    && promotions.map(promotion => {
      const isCommercial = !!promotion.url.includes('labels');

      return (
        <Item
          key={`promotion ${promotion.name}`}
          onClick={
            biAction && promotion.url
              ? () => {
                ReactGA.ga('ec:addPromo', {
                  name: 'Header - hamburger menu – buy now button',
                  id: 'buttom-and-hamburger-menu',
                  position: 'hamburger menu',
                });
                ReactGA.ga('ec:setAction', 'promo_click');
                ReactGA.ga(
                  'send',
                  'event',
                  'Internal Promotions',
                  'click',
                  'Header - hamburger menu – buy now button'
                );
                biAction({
                  actionCode: 3,
                  feature: 'Navigation',
                  featureType: 'Marketing',
                  campaignName: 'subscription',
                  campaignDetails: 'navigation_default',
                });
              }
              : null
          }
          variant="salesOpaque"
          miscStyles={{
            ...(isCommercial ? {
              ':hover': {
                backgroundColor: theme.color('masthead', 'bgMenuHover'),
                color: theme.color('masthead', 'textSiteLinksHover'),
              },
              ':focus': {
                backgroundColor: theme.color('masthead', 'bgMenuHover'),
                color: theme.color('masthead', 'textSiteLinksHover'),
                borderStyle: theme.mastheadStyle.focusBorderStyle,
              },
              ':active': {
                backgroundColor: theme.color('masthead', 'bgMenuHover'),
                color: theme.color('masthead', 'textSiteLinksHover'),
              },
              ...combinedSitesLabelStyle({ theme, }),
            } : {
              justifyContent: 'center',
              ':focus': {
                borderStyle: theme.mastheadStyle.focusBorderStyle,
              },
            }),
          }}
          name={promotion.name}
          url={promotion.url}
          attrs={{ 'data-google-interstitial': false, }}
        />
      );
    })
  );
}
function combinedGiftPromotions(giftPromotions, userType, biAction, theme, css) {
  return (
    giftPromotions
    && giftPromotions.map(promotion => (
      <Button
        boxModel={{ vp: 1, hp: 2, }}
        target="_blank"
        isFull
        fontSize={-2}
        onClick={
        biAction && promotion.url
          ? () => {
            ReactGA.ga('ec:addPromo', {
              name: 'Header - hamburger menu – buy now button',
              id: 'buttom-and-hamburger-menu',
              position: 'hamburger menu',
            });
            ReactGA.ga('ec:setAction', 'promo_click');
            ReactGA.ga(
              'send',
              'event',
              'Internal Promotions',
              'click',
              'Header - hamburger menu – buy now button'
            );
            biAction({
              actionCode: 49,
              feature: 'Desktop navigation',
              featureType: 'Marketing',
              campaignName: 'HDC Gift subscription button',
              campaignDetails: 'navigation_default',
            });
          }
          : null
      }
        href={promotion.url}
        miscStyles={{
          display: 'flex',
          justifyContent: 'flex-start',
          color: theme.color('sales', '-2'),
          backgroundColor: theme.color('masthead', 'bgMenuHover'),
          ':hover': {
            backgroundColor: theme.color('masthead', 'bgMenuHover'),
          },
          ':focus': {
            backgroundColor: theme.color('masthead', 'bgMenuHover'),
            borderStyle: theme.mastheadStyle.focusBorderStyle,
          },
          ':active': {
            backgroundColor: theme.color('masthead', 'bgMenuHover'),
          },
        }}
      >
        <span className={css({ color: '#ffa500', })}>{promotion.name}</span>
      </Button>
    ))
  );
}

const hamburgerWrapperStyle = ({ theme, }) => ({
  marginStart: '2rem',
  marginEnd: theme.mastheadStyle.hamburgerMarginEnd,
  position: 'relative',
});
/* eslint-disable react/prop-types */
function MenuButton({ biAction, toggleState, isOpen, themeColor, }, ref) {
  /* eslint-enable react/prop-types */
  const { theme, css, } = useFela({ isOpen, themeColor, });
  const buttonClasses = css(menuButtonStyle);
  const hamburgerWrapperClasses = css(hamburgerWrapperStyle);
  const buttonRef = useRef(null);
  useImperativeHandle(ref, () => ({
    focus: () => {
      buttonRef.current.focus();
    },
  }));

  return (
    <button
      className={buttonClasses}
      onClick={
        biAction
          ? () => {
            toggleState();
            biAction({ actionCode: isOpen ? 146 : 132, });
          }
          : toggleState
      }
      aria-expanded={isOpen}
      ref={buttonRef}
      type="button"
      data-test="navigationMenuDesktop"
    >
      <span className={hamburgerWrapperClasses}>
        <Hamburger isOpen={isOpen} color="current" size={3} isTransition />
      </span>
      <span>{theme.navigationMenuI18n.buttonText}</span>
    </button>
  );
}
// eslint-disable-next-line no-func-assign
MenuButton = React.forwardRef(MenuButton);
