import React, { useState, } from 'react';
import PropTypes from 'prop-types';
import { useFela, } from 'react-fela';
// import IconAvatar from '../../Icon/icons/IconAvatar';

UserButton.propTypes = {
  userName: PropTypes.string,
  userType: PropTypes.string,
  onClick: PropTypes.func,
  buttonRef: PropTypes.oneOfType([ PropTypes.func, PropTypes.shape({ current: PropTypes.object, }), ]),
  isButton: PropTypes.bool,
  themeColor: PropTypes.string,
  disableSeperator: PropTypes.bool,
  // eslint-disable-next-line consistent-return
  isOpen(props, propName) {
    if (props.isButton && typeof props[propName] !== 'boolean') {
      return new Error('The isOpen property is required when UserButton is indeed a button');
    }
  },
};
UserButton.defaultProps = {
  userName: null,
  userType: null,
  onClick: null,
  buttonRef: null,
  isButton: true,
  isOpen: null,
  themeColor: 'defaultTheme',
  disableSeperator: false,
};
function UserButton({ isOpen, userName, onClick, buttonRef, isButton, userType, themeColor, disableSeperator, }) {
  const [ isHovered, setIsHovered, ] = useState(false);
  const { css, theme, } = useFela();
  const Wrapper = isButton ? 'button' : 'span';
  const buttonClasses = css({
    height: '100%',
    color: theme.color('userMenu', 'iconColor'),
    display: 'flex',
    alignItems: 'center',
    border: 'none',
    paddingTop: '1rem',
    paddingInlineStart: '2rem',
    paddingInlineEnd: '2rem',
    '&:focus': { outline: 'none', },
    position: 'relative',
    ':after': disableSeperator ? {} : {
      content: '""',
      position: 'absolute',
      end: '-1px',
      height: '1.3em',
      width: theme.mastheadStyle.separatorWidth,
      alignSelf: 'center',
      backgroundColor: theme.color('userMenu', 'text'),
    },
    extend: [
      theme.getTransition(1, 'swiftOut'),
      theme.type(theme.mastheadStyle[userType ? 'typeScaleLoggedIn' : 'typeScale']),
      isOpen || isHovered
        ? {
          backgroundColor: isHovered
            ? theme.color('userMenu', 'bgHover')
            : theme.color(themeColor, 'mastheadText'),
          color: theme.color('userMenu', 'textOpenOrHover'),
        }
        : {},
      isHovered
        ? {
          ':after': {
            opacity: 0,
          },
        }
        : {},
      isButton ? { paddingBottom: '1.5rem', } : { paddingBottom: '1rem', },
    ],
  });

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  return (
    <Wrapper
      className={buttonClasses}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onFocus={handleMouseEnter}
      onBlur={handleMouseLeave}
      {...(isButton
        ? {
          ref: buttonRef,
          type: 'button',
          onClick,
          'aria-expanded': isOpen,
          'data-test': 'userNameButton',
        }
        : {})}
    >
      <span
        className={css({
          paddingBlockStart: '0.5rem',
          display: 'flex',
          alignItems: 'center',
          color:
            isHovered || isOpen
              ? theme.color('userMenu', 'textOpenOrHover')
              : theme.color(themeColor, 'mastheadText'),
          extend: [ theme.getTransition(1, 'swiftOut'), ],
        })}
      >
        {userName ? (
          <span
            className={css({
              fontWeight: '400',
            })}
          >
            {theme.userMenuI18n.buttonText}
            {','}
&nbsp;
          </span>
        ) : null}
        <span
          className={css({
            fontWeight: '700',
          })}
          data-test="userName"
        >
          {userName || theme.userMenuI18n.noUserData}
        </span>
      </span>
      {/* <IconAvatar size={3.5} miscStyles={{ marginRight: '2rem', }} attrs={{ 'aria-hidden': true, }} /> */}
    </Wrapper>
  );
}

export default UserButton;
