/* noOverWrite */

/* eslint-disable */
import React from 'react';
import { useFela, } from 'react-fela';
import iconStyle from '../iconStyle';
import { iconPropTypes, iconDefaultProps, } from '../iconPropTypes';

export default function IconReading({ size, fill, color, attrs, miscStyles, isFilled, ...props }) {
  const { css, } = useFela({ size, fill, color, miscStyles, });
  const className = css(iconStyle);
  const pathClassName = css({
    transitionProperty: 'fill',
    transitionDuration: '0.2s',
  });

  return (
    <svg width="0.84375em" height="1em" viewBox="0 0 216 256" className={className} {...props} {...attrs}>
      <path className={pathClassName} stroke="currentColor" strokeWidth="18" fill={isFilled ? 'currentColor' : 'transparent'} d="M164 215l-56-56-56 56V47h112v168z" />
    </svg>
  );
}

IconReading.propTypes = iconPropTypes;
IconReading.defaultProps = {
  ...iconDefaultProps,
  isFilled: true,
};
