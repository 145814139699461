import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { parseStyleProps, } from '@haaretz/htz-css-tools';
import { useFela, } from 'react-fela';
import { stylesPropType, } from '../../propTypes/stylesPropType';
import Query from '../ApolloBoundary/Query';
import Time from '../Time/Time';

const getPageDateTimeQuery = gql`
  query PageDateTime {
    pageDateTimeString @client
    a11yToggle @client
  }
`;

PageDateTime.propTypes = {
  /**
   * array of date-parts strings (week-day, hebrew-date, gregorian-date, hour)
   */
  dateParts: PropTypes.arrayOf(PropTypes.string).isRequired,
  a11yToggle: PropTypes.bool,
  /**
   * object of CSS styles.
   */
  miscStyles: stylesPropType,
};

PageDateTime.defaultProps = {
  miscStyles: null,
  a11yToggle: false,
};

/**
 * Internal component for page date-time
 * @param {object} param
 * @param {string[]} param.dateParts - array of date-parts strings (week-day, hebrew-date, gregorian-date, hour)
 * @param {object} param.miscStyles - object of CSS styles.
 */
function PageDateTime({ dateParts, a11yToggle, miscStyles, }) {
  const { theme, css, } = useFela({ a11yToggle, miscStyles, });
  // TODO: remove this after tests (now the Time component should handle this logic)

  const [ now, setNow, ] = React.useState(null);

  React.useEffect(() => {
    setNow(new Date());
  }, []);
  // const ilLocaleString = new Date().toLocaleString('en-US', {
  //   timeZone: 'Asia/Jerusalem',
  // });
  // const now = new Date(ilLocaleString).getTime();
  // const now = new Date();
  const strongTextClasses = css(stylePart, { fontWeight: 700, });
  const normalTextClasses = css(stylePart, { fontWeight: 400, });

  return dateParts.length === 4 ? (
    <Time
      className={css(wrapperStyles)}
      time={now}
      render={formattedTime => (
        <React.Fragment>
          <span className={strongTextClasses}>{dateParts[0]}</span>
          <span className={normalTextClasses}>{dateParts[1]}</span>
          { now ? (
            <>
              <Time tagName="span" format={theme.pageDateTime && theme.pageDateTime.dateFormat ? theme.pageDateTime.dateFormat : 'dd.MM.yyyy'} time={now} className={strongTextClasses} timeZone="Asia/Jerusalem" />
              <Time tagName="span" label={theme.pageDateTime && theme.pageDateTime.stringBeforeTimeFormat ? `${theme.pageDateTime.stringBeforeTimeFormat} ` : null} format={theme.pageDateTime && theme.pageDateTime.timeFormat ? theme.pageDateTime.timeFormat : 'HH:mm'} time={now} className={normalTextClasses} timeZone="Asia/Jerusalem" />
            </>
          ) : null}
        </React.Fragment>
      )}
    />
  ) : null;
}

// ////////////////////////////////////////////////////////////////// //
//                               Styles                               //
// ////////////////////////////////////////////////////////////////// //

function wrapperStyles({ a11yToggle, miscStyles, theme, }) {
  return {
    color: theme.color('neutral', a11yToggle ? '-2' : '-3'),
    fontFamily: theme.fontStacks[theme.pageDateTime.fontFamily],
    extend: [
      theme.type(-3),
      ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
    ],
  };
}

function stylePart({ theme, }) {
  return {
    unicodeBidi: 'isolate',
    paddingInlineEnd: '.5rem',
    '&:last-child:after': {
      content: 'unset',
    },
    '&:after': {
      paddingInlineStart: '.5rem',
      fontWeight: 400,
      content: theme.pageDateTime.separator,
    },
  };
}

// ////////////////////////////////////////////////////////////////// //
//                          Helper Functions                          //
// ////////////////////////////////////////////////////////////////// //

/**
 * Component for page date-time.
 * The page date-time comes from the Apollo store.
 * @param {object} param
 * @param {object} param.miscStyles - object of CSS styles.
 */
export default function PageDateTimeWrapper({ miscStyles, }) {
  return (
    <Query query={getPageDateTimeQuery}>
      {({ loading, error, data, }) => {
        if (loading || error || !(data && data.pageDateTimeString)) {
          return null;
        }
        return (
          <PageDateTime
            dateParts={data.pageDateTimeString}
            a11yToggle={data.a11yToggle}
            miscStyles={miscStyles}
          />
        );
      }}
    </Query>
  );
}

PageDateTimeWrapper.propTypes = {
  miscStyles: stylesPropType,
};

PageDateTimeWrapper.defaultProps = {
  miscStyles: null,
};
