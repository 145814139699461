/* global document window Event */
// @flow
import React, { useRef, Fragment, } from 'react';
import { useFela, } from 'react-fela';
import { useQuery, } from 'react-apollo';
import VisuallyHidden from '../../VisuallyHidden/VisuallyHidden';
import ClickArea from '../../ClickArea/ClickArea';
import DropdownList from '../../DropdownList/DropdownList';
import IconAccessibility from '../../Icon/icons/IconAccessibility';
import Item from '../../DropdownList/DropdownItem';
import useMastheadDisplayed from '../useMastheadDisplayed';
import { dropdownItemStyle, dropdownListStyle, } from '../mastheadDropdownListStyle';
import useIsAnimationReduced from '../../../hooks/useIsAnimationReduced';
import GET_A11Y_STATE from './queries/getA11yState.graphql';

type Props = {
  themeColor: string,
}

const normalStyle = (theme, themeColor) => ({
  color: theme.color(themeColor, 'mastheadIcon'),
  backgroundColor: 'transparent',
});

const openedStyle = theme => ({
  backgroundColor: theme.color('masthead', 'bgMenu'),
  color: theme.color('neutral', '-10'),
});

const focusedStyle = theme => ({
  color: theme.color('white'),
  backgroundColor: theme.color('masthead', 'bgButtonHover'),
  outline: 'none',
});

const TAB_INDEX = 1;
const HTZ = '__HTZ';

MastheadA11yMenu.defaultProps = {
  themeColor: 'defaultTheme',
};
/**
 * A menu component for the page header. A component which generate
 * two options: toggle accessibility on apollo link state and report a problem via email
 */
export default function MastheadA11yMenu({ themeColor, }: Props) {
  const buttonRef = useRef(null);
  const { theme, css, } = useFela();
  const mastheadDisplayed = useMastheadDisplayed();
  const [ pageWillRefresh, setPageWillRefresh, ] = React.useState(false);
  const cancelRefresh = React.useCallback(closeList => {
    if (window[HTZ] && typeof window[HTZ].cancelRefresh === 'function') {
      window[HTZ].cancelRefresh();
      setPageWillRefresh(false);
    }
    closeList && closeList();
  }, []);

  React.useEffect(() => {
    if (window[HTZ] && window[HTZ].cancelRefresh && !window[HTZ].cancelRefresh.activated) {
      setPageWillRefresh(true);
    }
  }, []);

  const { data: a11yStateData, client, } = useQuery(GET_A11Y_STATE);
  const { a11yToggle = false, } = a11yStateData || {};
  const isAnimationReduced = useIsAnimationReduced();

  const toggleA11yState = closeList => () => {
    client.writeData({
      data: {
        a11yToggle: !a11yToggle,
      },
    });
    closeList();
  };

  const toggleAnimationReduced = closeList => () => {
    const nextIsAnimationReduced = !isAnimationReduced;
    client.writeData({
      data: {
        isAnimationReduced: nextIsAnimationReduced,
      },
    });
    closeList();
  };

  const itemMiscStyles = {
    backgroundColor: theme.color('masthead', 'bgMenu'),
    ':hover': {
      backgroundColor: theme.color('masthead', 'bgMenuHover'),
    },
    ':focus': {
      backgroundColor: theme.color('masthead', 'bgMenuHover'),
      borderStyle: theme.mastheadStyle.focusBorderStyle,
    },
    ':active': {
      backgroundColor: theme.color('masthead', 'bgMenuHover'),
    },
  };

  const initialItems = theme.a11yMenuI18n.menuItems;
  const combinedItems = closeList => [
    {
      name: theme.a11yMenuI18n.a11yToggle(a11yToggle),
      onClick: toggleA11yState(closeList),
    },
    {
      name: theme.a11yMenuI18n.animationToggle(isAnimationReduced),
      onClick: toggleAnimationReduced(closeList),
    },
    ...(pageWillRefresh ? [
      {
        name: theme.a11yMenuI18n.cancelRefresh,
        onClick: cancelRefresh.bind(null, closeList),
      },
    ] : []),
    ...initialItems,
  ].map((item, index) => (
    <Item
      key={item.name}
      onClick={item.onClick || closeList}
      miscStyles={itemMiscStyles}
      attrs={{ tabIndex: TAB_INDEX, }}
      {...item}
    />
  ));

  return (
    <DropdownList
      mainMenuStyle={{ position: 'relative', display: 'flex', }}
      onClose={() => {
        buttonRef.current && buttonRef.current.focus();
      }}
      render={({ renderButton, ListWrapper, isOpen, closeList, }) => {
        if (isOpen && !mastheadDisplayed) closeList();
        return (
          <Fragment>
            {renderButton(({ toggleState, }) => (
              <button
                aria-describedby="masthead-a11y-dropdown-button"
                // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                tabIndex={TAB_INDEX}
                type="button"
                className={css({
                  transitionProperty: 'all',
                  outline: 'none',

                  ...(isOpen
                    ? {
                      ...openedStyle(theme),
                      '&:hover': focusedStyle(theme),
                      '&:focus:hover': focusedStyle(theme),
                      '&:focus': openedStyle(theme),
                    }
                    : {
                      ...normalStyle(theme, themeColor),
                      '&:hover': focusedStyle(theme),
                      '&:focus': focusedStyle(theme),
                    }),

                  extend: [
                    theme.getTransition(1, 'swiftIn'),
                  ],
                })}
                ref={buttonRef}
                onClick={toggleState}
              >
                <ClickArea
                  tagName="span"
                  size={6}
                  attrs={{ 'aria-hidden': true, }}
                  miscStyles={{
                    position: 'relative',
                    ':hover': {
                      ':before': {
                        content: '""',
                        position: 'absolute',
                        start: '-1px',
                        top: '0',
                        width: '1px',
                        height: '100%',
                        backgroundColor: !themeColor === 'defaultTheme' ? theme.color(themeColor, 'mastheadText') : null,
                      },
                    },
                  }}
                >
                  <IconAccessibility size={3.5} />
                </ClickArea>
                <VisuallyHidden id="masthead-a11y-dropdown-button">
                  {theme.a11yMenuI18n.a11yTitle}
                </VisuallyHidden>
              </button>
            ))}
            {isOpen ? (
              <ListWrapper
                listStyle={{ ...dropdownListStyle(theme), start: 'unset', end: '0', }}
                itemStyle={dropdownItemStyle(theme)}
                closeList={closeList}
              >
                {combinedItems(closeList)}
              </ListWrapper>
            ) : null}
          </Fragment>
        );
      }}
    />
  );
}
