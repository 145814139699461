// @flow
/* global localStorage */
import React from 'react';

import useBreadcrumbsData from '../../hooks/Page/useBreadcrumbsData';
import { writeToLocalStorage, } from '../../utils/writeToStorage';
import useArticleId from '../../hooks/Page/useArticleId';
import checkSiteFromConfig from '../../utils/checkSiteFromConfig';

const { isTM, } = checkSiteFromConfig();

type breadcrumbItemType = {
  contentId: string,
  body: string,
  href: string,
  target: string,
}

export const LAST_ALL_SECTIONS_KEY = '_lst4sbmdr';
export const LAST_MAIN_SECTIONS_KEY = '_lst50mnmdr';
export const LAST_ARTICLES_KEY = '_lst20ar';

const excludedSections = [ 'news', 'tmr', 'labels', 'smadar', ];


const sitePathSegment = isTM ? 'themarker' : 'haaretz';

function updateLastSections(section, key, saveCount) {
  try {
    const lastSectionsJSON = localStorage.getItem(key) || '[]';
    const lastSections = JSON.parse(lastSectionsJSON)
      .filter(({ contentId, }) => contentId !== section.contentId);

    lastSections.unshift({
      contentId: section.contentId,
      url: section.href,
      name: section.body,
    });

    writeToLocalStorage({ key, value: JSON.stringify(lastSections.slice(0, saveCount)), });
  }
  catch (e) {
    console.error(e);
  }
}

function updateLastRead(breadcrumbs: breadcrumbItemType[]) {
  const site = breadcrumbs[breadcrumbs.length - 1];
  const subSection = breadcrumbs[1];
  const mainSection = breadcrumbs[breadcrumbs.length - 2];
  if (site.target !== sitePathSegment) return;

  updateLastSections(mainSection, LAST_MAIN_SECTIONS_KEY, 50);

  if (excludedSections.includes(mainSection.target)) return;

  updateLastSections(subSection, LAST_ALL_SECTIONS_KEY, 4);


  // TODO: updateLastArticles when we're sure what data would be needed there
}

export default function LastArticlesUpdate(): null {
  const breadcrumbs: breadcrumbItemType[] | null = useBreadcrumbsData();
  const articleId = useArticleId();

  React.useEffect(() => {
    breadcrumbs && updateLastRead(breadcrumbs);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ articleId, ]);

  return null;
}
