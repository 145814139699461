// @flow

import * as React from 'react';
import { useFela, } from 'react-fela';
import { type StyleProps, } from '@haaretz/htz-css-tools';
import IconSearch from '../../Icon/icons/IconSearch';
import ClickArea from '../../ClickArea/ClickArea';

type SearchButtonPropsType = {
  onClick: () => void,
  isActive: boolean,
  buttonRef: ?any,
  miscStyles: ?StyleProps,
  themeColor: ?string,
};

const searchButtonMiscStyle = ({ theme, miscStyles, themeColor, }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  color: theme.color(themeColor, 'mastheadText'),
  padding: '1rem',
  fontWeight: 700,
  type: -1,
  ':hover': {
    backgroundColor: theme.color('masthead', 'bgButtonHover'),
    color: theme.color('button', 'primaryOpaqueText'),
  },

  ':focus': {
    backgroundColor: theme.color('masthead', 'bgButtonHover'),
    color: theme.color('button', 'primaryOpaqueText'),
  },
  ...(miscStyles || {}),
});

const searchButtonIconMiscStyle = ({ theme, themeColor, }) => ({
  marginInlineEnd: theme.mastheadStyle.searchButtonMarginEnd,
  color: 'inherit',
});

function SearchButtonComponent(props: SearchButtonPropsType) {
  const { buttonRef, isActive, onClick, miscStyles, themeColor, } = props;
  const { theme, } = useFela({ miscStyles, });

  return (
    <ClickArea
      ref={buttonRef}
      size={6}
      miscStyles={searchButtonMiscStyle({ theme, miscStyles, themeColor, })}
      attrs={{
        'aria-expanded': isActive,
        'aria-label': isActive
          ? theme.headerSearchI18n.a11yTexts.close
          : theme.headerSearchI18n.a11yTexts.execSearch,
        'data-test': 'searchButton',
      }}
      onClick={onClick}
    >
      <IconSearch size={3.7} color="primary" miscStyles={searchButtonIconMiscStyle({ theme, themeColor, })} />
      <div>{theme.headerSearchI18n.buttonText}</div>
    </ClickArea>
  );
}

SearchButtonComponent.defaultProps = {
  isActive: false,
  onClick: null,
  buttonRef: null,
  miscStyles: null,
};

// TODO: We need to update flow to a newer version
// that includes a definition for `forwardRef`.
// Until then:
// $FlowFixMe
const SearchButton = React.forwardRef((props, ref) => (
  <SearchButtonComponent {...props} buttonRef={ref} />
));

export default SearchButton;
