/* global window */
import React, { Fragment, } from 'react';
import PropTypes from 'prop-types';
import { useFela, } from 'react-fela';
import Button from '../Button/Button';
import FlippingArrow from '../Animations/FlippingArrow';
import DropdownList from './DropdownList';
import EventTracker from '../../utils/EventTracker';
import VisuallyHidden from '../VisuallyHidden/VisuallyHidden';
import { dropdownItemStyle, dropdownListStyle, } from '../Masthead/mastheadDropdownListStyle';
import { attrsPropType, } from '../../propTypes/attrsPropType';
import BSException from '../BSException/BSException';

Item.propTypes = {
  /**
   * The item's name to display.
   */
  name: PropTypes.string.isRequired,
  /**
   * Item's destination.
   */
  url: PropTypes.string,
  /** An onClick function */
  onClick: PropTypes.func,
  /**
   * Item's pages (may contain pages or sub-pages with their own pages).
   */
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * The page's name to display.
       */
      name: PropTypes.string,
      /**
       * Page's destination.
       */
      url: PropTypes.string,
      /**
       * 3rd+ level of sub-pages.
       */
      pages: PropTypes.arrayOf(PropTypes.object),
    })
  ),
  /**
   * Button's variant to be used.
   */
  variant: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  miscStyles: PropTypes.object,
  attrs: attrsPropType,
};

Item.defaultProps = {
  onClick: null,
  pages: null,
  url: null,
  variant: 'secondaryOpaque',
  miscStyles: {},
  attrs: null,
};

export default function Item({ name, url, pages, variant, miscStyles, onClick, attrs, ...props }) {
  const { theme, } = useFela();

  return (
    <EventTracker>
      {({ biAction, }) => (pages && pages.length > 0 ? (
        <DropdownList
          isLast
          mainMenuStyle={{ width: '100%', display: 'flex', }}
          render={({ renderButton, ListWrapper, isOpen, }) => {
            const buttonRef = React.createRef();
            const combinedItems = pages.map(item => {
              const isPromotedLabel = item.kind === 'commercial';
              return (
                <Item
                  key={item.name}
                  onClick={
                      biAction
                        ? () => {
                          biAction({
                            actionCode: 133,
                            additionalInfo: { name: item.name, },
                          });
                        }
                        : null
                    }
                  miscStyles={{
                    ...(isPromotedLabel
                      ? {
                        color: theme.color('commercial'),
                        ':visited': {
                          color: theme.color('commercial'),
                        },
                      }
                      : {}),
                    fontWeight: theme.mastheadStyle.fontWeight,
                    backgroundColor: isPromotedLabel
                      ? theme.color('neutral', '-6')
                      : theme.color('masthead', 'subMenuBg'),
                    ':hover': {
                      backgroundColor: theme.color('masthead', 'subMenuBgHover'),
                    },
                    ':focus': {
                      backgroundColor: theme.color('masthead', 'subMenuBgHover'),
                      borderStyle: theme.mastheadStyle.focusBorderStyle,
                    },
                  }}
                  {...item}
                />
              );
            });
            const list = {};
            const eventsAttrs = (openList, closeList) => ({
              onMouseOver: () => {
                if (typeof list.closeSubList !== 'undefined') {
                  window.clearTimeout(list.closeSubList);
                }
                list.openSubList = window.setTimeout(openList, 250);
              },
              onMouseOut: () => {
                if (typeof list.openSubList !== 'undefined') {
                  window.clearTimeout(list.openSubList);
                }
                list.closeSubList = window.setTimeout(closeList, 250);
              },
            });

            return (
              <Fragment>
                {renderButton(({ toggleState, openList, closeList, }) => (
                  <Fragment>
                    <Button
                      boxModel={{ vp: 1, hp: 2, }}
                      isFull
                      fontSize={-2}
                      variant={variant}
                      onClick={onClick}
                      attrs={{
                        ...(attrs || {}),
                        ...eventsAttrs(openList, closeList, list),
                      }}
                      href={url}
                      miscStyles={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        ...miscStyles,
                      }}
                    >
                      <span>{name}</span>
                    </Button>
                    <Button
                      boxModel={{ vp: 1, hp: 2, }}
                      variant={variant}
                      isHard
                      onClick={toggleState}
                      attrs={{
                        'aria-expanded': isOpen,
                        ...(attrs || {}),
                        ...eventsAttrs(openList, closeList, list),
                      }}
                      aria-label={`more ${name}`}
                      ref={buttonRef}
                      miscStyles={{
                        position: 'static',
                        ':hover': {
                          backgroundColor: theme.color('masthead', 'flippingArrowButtonBgOpen'),
                        },
                        ':focus': {
                          borderStyle: theme.mastheadStyle.focusBorderStyle,
                        },
                        ...(isOpen
                          ? {
                            backgroundColor: theme.color(
                              'masthead',
                              'flippingArrowButtonBgOpen'
                            ),
                          }
                          : {}),
                      }}
                    >
                      <VisuallyHidden>
                        {theme.navigationMenuI18n.a11yTexts.subMenu(name, isOpen)}
                      </VisuallyHidden>
                      <FlippingArrow
                        attrs={{ 'aria-hidden': true, }}
                        isOpen={isOpen}
                        color={[ 'masthead', 'flippingArrow', ]}
                        size={1.5}
                        direction={theme.direction}
                      />
                    </Button>

                    {isOpen && (
                    <ListWrapper
                      attrs={eventsAttrs(openList, closeList, list)}
                      listStyle={{
                        ...dropdownListStyle(theme),
                        top: '0',
                        start: '100%',
                        position: 'absolute',
                      }}
                      itemStyle={{
                        ...dropdownItemStyle(theme),
                        ':last-child': {
                          borderBottomColor: theme.color('primary', '+1'),
                          borderBottomStyle: 'solid',
                          borderBottomWidth: '1px',
                        },
                      }}
                      closeList={evt => {
                        closeList(evt);
                        buttonRef.current && buttonRef.current.focus();
                      }}
                    >
                      {combinedItems}
                    </ListWrapper>
                    )}
                  </Fragment>
                ))}
              </Fragment>
            );
          }}
        />
      ) : (
        <Button
          boxModel={{ vp: 1, hp: 2, }}
          isFull
          fontSize={-2}
          variant={variant}
          onClick={onClick}
          href={url}
          attrs={attrs}
          miscStyles={{
            display: 'flex',
            justifyContent: 'flex-start',
            ...miscStyles,
          }}
        >
          <span>{name}</span>
        </Button>
      ))
      }
    </EventTracker>
  );
}
