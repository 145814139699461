// @flow
import config from 'config';
import type { EnvironmentType, } from '../flowTypes/EnvironmentType';

const connectionPresetsMap = new Map([
  [ 'dev', 'dev', ],
  [ 'dev2prod', 'prod', ],
  [ 'qa', 'qa', ],
  [ 'pre-stage', 'stage', ],
  [ 'stage', 'preProd', ],
  [ 'pre-prod', 'preProd', ],
  [ 'pre-test', 'test', ],
  [ 'prodBeta', 'prod', ],
  [ 'canary', 'prod', ],
  [ 'prod', 'prod', ],
]);


function useEnvironment(): EnvironmentType {
  const connectionPreset = config.get('connectionPreset');
  const isProduction = config.has('configName') ? config.get('configName') === 'production' : false;

  const environment = connectionPresetsMap.get(connectionPreset) || (isProduction ? 'prod' : 'dev');

  return environment;
}

export default useEnvironment;
