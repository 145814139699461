import React from 'react';
import PropTypes from 'prop-types';
import { useFela, } from 'react-fela';
import ClickArea from '../ClickArea/ClickArea';
import hoverableButtonRule from './hoverableButtonRule';
import withA11yState from '../A11yState/withA11yState';
import VisuallyHidden from '../VisuallyHidden/VisuallyHidden';
import useOneTime from '../../hooks/useOneTime';
import { useEventTracker, } from '../../utils/EventTracker';
import useMedia from '../../hooks/useMedia';

MastheadSubscription.propTypes = {
  a11yState: PropTypes.bool,
  isSubscriptionGift: PropTypes.bool,
  isPartialSubscription: PropTypes.bool,
};

MastheadSubscription.defaultProps = {
  a11yState: false,
  isSubscriptionGift: false,
  isPartialSubscription: false,
};


function MastheadSubscription({ a11yState, isPartialSubscription, isSubscriptionGift, }) {
  const { theme, css, } = useFela();
  const isFromMobileBreak = useMedia({ query: { from: 's', }, });

  const { biImpression, biAction, } = useEventTracker();

  useOneTime(isFromMobileBreak && typeof biImpression === 'function' && biImpression.length > 0, () => biImpression({
    feature: isSubscriptionGift ? 'Header buttons' : 'Hello Subscription',
    featureType: 'Marketing',
    campaignName: isSubscriptionGift ? 'HDC Gift subscription button' : isPartialSubscription ? 'upsell' : 'subscription',
    ...!isSubscriptionGift ? { campaignDetails: 'hello_default', } : {},
  }));

  const { url, buyText, upgradeText, subscriptionGiftUrl, subscriptionGiftText, a11ySubscriptionGiftText, upSellUrl, } = theme.mastheadSubscriptionI18n;
  const miscStyles = hoverableButtonRule({ theme, });

  return (
    <ClickArea
      href={isSubscriptionGift ? subscriptionGiftUrl : isPartialSubscription ? upSellUrl : url}
      target={isSubscriptionGift ? '_blank' : undefined}
      onClick={
        biAction
          ? () => {
            isSubscriptionGift ? biAction({
              actionCode: 49,
              feature: 'Header buttons',
              featureType: 'Marketing',
              campaignName: 'HDC Gift subscription button',
            }) : biAction({
              feature: 'Hello Subscription',
              featureType: 'Marketing',
              actionCode: 3,
              campaignName: isPartialSubscription ? 'upsell' : 'subscription',
              campaignDetails: 'hello_default',
            });
          } : null
      }
      size={6}
      miscStyles={{
        ...miscStyles,
        ...theme.type(theme.mastheadStyle.typeScale),
        display: 'flex',
        alignItems: 'center',
        fontWeight: '700',
        paddingTop: '1rem',
        paddingBottom: '1rem',
        paddingInlineStart: '2rem',
        paddingInlineEnd: '2rem',
        color: theme.color('sales', a11yState ? 'a11yOnLight' : '+2'),
        ':visited': {
          color: theme.color('sales', a11yState ? 'a11yOnLight' : '+2'),
        },
        ':after': {
          content: '""',
          position: 'absolute',
          end: '-1px',
          height: '1.3em',
          width: theme.mastheadStyle.separatorWidth,
          backgroundColor: theme.color('userMenu', 'text'),
        },
        ':hover': {
          ':after': {
            backgroundColor: 'transparent',
          },
          ':before': {
            content: '""',
            position: 'absolute',
            start: '-1px',
            top: '0',
            width: '1px',
            height: '100%',
            backgroundColor: theme.color('white'),
          },
        },
      }}
    >
      <span className={css({
        paddingBlockStart: '0.5rem',
        ...isSubscriptionGift ? theme.type(-1) : {},
      })}
      >
        {isSubscriptionGift ? subscriptionGiftText : isPartialSubscription ? upgradeText : buyText }

      </span>
      <VisuallyHidden>{isSubscriptionGift ? a11ySubscriptionGiftText : isPartialSubscription ? upgradeText : buyText}</VisuallyHidden>
    </ClickArea>
  );
}

export default withA11yState(MastheadSubscription);
