// @flow
import React from 'react';
import { useFela, } from 'react-fela';
import { borderTop, } from '@haaretz/htz-css-tools';
import HtzLink from '../HtzLink/HtzLink';
import { useEventTracker, } from '../../utils/EventTracker';

type Props = {
  menuItems: {
    items: Object[],
  },
};
export default function MadorimNavigation({ menuItems, }: Props) {
  const { css, theme, } = useFela();
  const { biAction, } = useEventTracker();
  if (!menuItems || !Array.isArray(menuItems.items)) return null;

  const wrapperClasses = css({
    textAlign: 'center',
    paddingTop: '1rem',
    // paddingBottom: '1rem',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    extend: [ borderTop('1px', 1, 'solid', theme.color('layout', 'rowBg')), ],
  });
  const itemClasses = css({
    display: 'inline',
    fontWeight: 700,
    extend: [ theme.type(-2), ],
  });

  return (
    <nav>
      <ul className={wrapperClasses}>
        {menuItems.items.map((item, idx) => {
          if (item && !item.name) return null;
          const isLast = idx === menuItems.items.length - 1;
          return (
            <li className={itemClasses} key={item.contentId || item.url}>
              <HtzLink
                href={item.url}
                className={css({ marginInlineStart: '.5rem', marginInlineEnd: '.5rem', })}
                onClick={() => {
                  biAction({
                    actionCode: 133,
                    featureType: 'Content',
                    feature: 'Top navigation',
                    campaignDetails: item.name,
                  });
                }}
              >
                {item.name}
              </HtzLink>
              {isLast ? '' : ' | '}
            </li>
          );
        })}
      </ul>
    </nav>
  );
}
