import React from 'react';
import gql from 'graphql-tag';
import log from 'loglevel';
import Announcer from './Announcer';
import Query from '../ApolloBoundary/Query';

const propTypes = {};

const defaultProps = {};

const GET_ARIA_LIVE_MESSAGE = gql`
  query GetAriaLive {
    ariaLive @client {
      politeMessage
      assertiveMessage
    }
  }
`;

const AriaLive = () => (
  <Query query={GET_ARIA_LIVE_MESSAGE}>
    {({ error, loading, data, }) => {
      if (error) {
        log.error(error);
        return null;
      }
      if (loading) return null;
      const { ariaLive: { politeMessage, assertiveMessage, }, } = data;
      return (
        <Announcer
          assertiveMessage={assertiveMessage}
          politeMessage={politeMessage}
        />
      );
    }}
  </Query>
);

AriaLive.propTypes = propTypes;
AriaLive.defaultProps = defaultProps;

export default AriaLive;
