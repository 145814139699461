// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import gql from 'graphql-tag';
import config from 'config';
import { useQuery, } from '@apollo/react-hooks';
import { type StyleProps, } from '@haaretz/htz-css-tools';
import PageDateTime from './PageDateTime';
import HtzLink from '../HtzLink/HtzLink';
import VisuallyHidden from '../VisuallyHidden/VisuallyHidden';
import useGetComponent from '../../hooks/GetComponentContext/useGetComponent';

const getIsLabel = gql`
  query getIsLabel {
    isLabel @client
  }
`;

const wrapperStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

type LogoAndDateProps = {
  disableDatetime: ?boolean,
  datetimeMiscStyles: ?StyleProps,
  tabIndex: ?number,
  disableLink?: ?boolean,
  themeColor: ?string,
};

LogoAndDate.defaultProps = {
  datetimeMiscStyles: null,
  disableDatetime: false,
  tabIndex: 0,
  disableLink: false,
  themeColor: 'defaultTheme',
};
export default function LogoAndDate({
  disableDatetime,
  datetimeMiscStyles,
  tabIndex,
  disableLink,
  themeColor,
}: LogoAndDateProps) {
  const { css, theme, } = useFela();
  const getComponent = useGetComponent();
  const isHdc = config.get('siteNumber') === 85;

  const { data, } = useQuery(getIsLabel);
  const isLabel = (data || {}).isLabel || false;
  const Logo = isLabel ? getComponent('mastheadLabelLogo') : getComponent('mastheadLogo');

  return (
    <div
      className={css(wrapperStyle)}
      data-google-interstitial={false}
    >
      {Logo ? (
        disableLink ? (
          <Logo themeColor={themeColor} />
        ) : (
          <HtzLink href={isLabel ? (isHdc ? '/haaretz-labels' : '/labels') : '/'} attrs={{ tabIndex, }}>
            <Logo themeColor={themeColor} />
            <VisuallyHidden>{theme.homepageLogoLink.a11yText}</VisuallyHidden>
          </HtzLink>
        )
      ) : null}
      {disableDatetime ? null : <PageDateTime miscStyles={datetimeMiscStyles} />}
    </div>
  );
}
