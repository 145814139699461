// @flow
import * as React from 'react';
import H from '../AutoLevels/H';
import HtzLink from '../HtzLink/HtzLink';
import IconBack from '../Icon/icons/IconBack';
import VisuallyHidden from '../VisuallyHidden/VisuallyHidden';
import type { MenuItemType, } from './MenuItemType';

type biActionType = ({ actionCode: number, }) => void;
type setNavBiActionType = ({
  name: string,
  isPersonal?: boolean,
  isMarketing?: boolean,
  featureType?: ?string,
}) => ?biActionType;

type MenuSectionPagesProps = {
  pages: MenuItemType[],
  showAll: boolean,
  toggleShowAll: () => void,
  setNavBiAction: setNavBiActionType,
  linkClassName: string,
  css: (Object | (Object => Object)) => string,
  theme: Object,
};

type MoreBackButtonProps = {
  className?: string,
  isBack?: boolean,
  onClick: () => void,
  css: (Object | (Object => Object)) => string,
  theme: Object,
  Tag: string,
};

MoreBackButton.defaultProps = { className: '', isBack: false, Tag: 'li', };
function MoreBackButton({
  className,
  onClick,
  isBack,
  css,
  theme,
  Tag,
}: MoreBackButtonProps): React.Node {
  return (
    <Tag className={className}>
      <button
        type="button"
        className={css({
          outline: 'none',
          display: 'flex',
          alignItems: 'center',
          paddingTop: '1rem',
          paddingBottom: '1rem',
          minHeight: '6rem',
          minWidth: '6rem',
          flexDirection: isBack ? 'row-reverse' : null,
        })}
        onClick={onClick}
      >
        {isBack ? (
          theme.mobileNavigationMenuI18n.back
        ) : (
          <VisuallyHidden>{theme.mobileNavigationMenuI18n.more}</VisuallyHidden>
        )}
        <div
          aria-hidden
          className={css({
            backgroundColor: theme.color('mobileNav', 'moreBackBg'),
            color: theme.color('mobileNav', 'moreBackArrow'),
            width: '3rem',
            height: '3rem',
            borderRadius: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            transform: `rotate(${isBack ? (theme.direction === 'ltr' ? 0 : 180) : -90}deg)`,
            marginInlineEnd: isBack ? '1rem' : undefined,
          })}
        >
          <IconBack size={2.2} />
        </div>
      </button>
    </Tag>
  );
}

const delayDifferenceBetweenItems = 0.1;
function MenuSectionPages({
  pages,
  showAll,
  toggleShowAll,
  setNavBiAction,
  linkClassName,
  css,
  theme,
}: MenuSectionPagesProps): React.Node {
  const pagesToShow = showAll ? pages : pages.slice(0, 2);
  const baseItemClassName = css({
    ...(pages.length > 3 && showAll
      ? {
        overflow: 'hidden',
        animationDuration: '0.3s',
        animationFillMode: 'both',
        animationTimingFunction: 'linear',
        animationName: {
          '0%': { opacity: '0', transform: 'translateY(20%)', },
          '90%': { opacity: 0.6, transform: 'translateY(0)', },
          '100%': { opacity: 1, },
        },
      }
      : {}),
  });
  return (
    <ul className={css({ marginBottom: '3rem', })}>
      {pagesToShow.map((page, i) => (
        <li
          key={page.name}
          className={`${baseItemClassName} ${css({
            ...(page.kind === 'commercial' ? { color: theme.color('commercial'), } : {}),
            animationDelay: `${i * delayDifferenceBetweenItems + 0.3}s`,
          })}`}
        >
          <HtzLink
            href={page.url}
            className={linkClassName}
            onClick={setNavBiAction({ name: page.name, })}
          >
            {page.name}
          </HtzLink>
        </li>
      ))}
      {pages.length > 3 && (
        <MoreBackButton
          className={`${baseItemClassName} ${css({
            animationDelay: `${pages.length * delayDifferenceBetweenItems + 0.3}s`,
          })}`}
          onClick={toggleShowAll}
          isBack={showAll}
          css={css}
          theme={theme}
        />
      )}
    </ul>
  );
}

const sectionHeaderStyle = ({ theme, }) => ({
  ...theme.type(2),
  // paddingTop: '2rem',
});

type MenuSectionProps = {
  section: MenuItemType,
  tag: string,
  showAll: boolean,
  setFullSubMenuName: (?string) => void,
  biAction: ?biActionType,
  setNavBiAction: setNavBiActionType,
  linkClassName: string,
  css: (Object | (Object => Object)) => string,
  theme: Object,
};

MenuSection.defaultProps = { showAll: false, };
export default function MenuSection({
  section,
  tag: Tag,
  showAll,
  setFullSubMenuName,
  biAction,
  setNavBiAction,
  linkClassName,
  css,
  theme,
}: MenuSectionProps): React.Node {
  const allShown = showAll || (!!section.pages && section.pages.length < 4);
  const toggleShowAll = () => {
    setFullSubMenuName(allShown ? null : section.name);
    if (!allShown && biAction) {
      biAction({ actionCode: 148, additionalInfo: { section: section.name, }, });
    }
  };
  return (
    <Tag>
      {section.pages && section.pages.length > 3 && allShown && (
        <MoreBackButton onClick={toggleShowAll} isBack css={css} theme={theme} Tag="div" />
      )}
      <HtzLink
        className={linkClassName}
        href={section.url}
        onClick={setNavBiAction({ name: section.name, })}
      >
        <H className={css(sectionHeaderStyle)}>{section.name}</H>
      </HtzLink>
      {section.pages && (
        <MenuSectionPages
          pages={section.pages}
          showAll={allShown}
          toggleShowAll={toggleShowAll}
          setNavBiAction={setNavBiAction}
          linkClassName={linkClassName}
          css={css}
          theme={theme}
        />
      )}
    </Tag>
  );
}
