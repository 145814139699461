// @flow
/* global localStorage sessionStorage */

type Args = {
  key: string,
  value: any,
  errorMessage?: string,
};

export function writeToLocalStorage({
  key,
  value,
  errorMessage = 'default error message from writeToLocalStorage util ',
}: Args) {
  try {
    localStorage.setItem(key, value);
  }
  catch (err) {
    console.error(errorMessage, err);
  }
}

export function writeToSessionStorage({
  key,
  value,
  errorMessage = 'default error message from writeToLocalStorage util ',
}: Args) {
  try {
    sessionStorage.setItem(key, value);
  }
  catch (err) {
    console.error(errorMessage, err);
  }
}
