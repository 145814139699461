// @flow
import React, { type Node, } from 'react';
import { useFela, } from 'react-fela';

type MenuLogoProps = {
  requestedShape: ?'Logo' | 'Menu' | 'Close',
};

const duration = '0.2s';

const svgStyles = ({ theme, }) => ({
  padding: '2rem',
  transform: 'scale(1)',
  width: '11rem',
  height: '11rem',
  backgroundColor: theme.color('mobileNav', 'logoBg'),
  borderStyle: 'solid',
  borderWidth: '3px',
  borderColor: theme.color('white'),
  borderRadius: '100%',
  fill: theme.color('white'),
  cursor: 'pointer',
  transition: duration,
  ':focus': {
    backgroundColor: theme.color('mobileNav', 'logoBgActive'),
    outline: 'none',
  },
  ':active': {
    backgroundColor: theme.color('mobileNav', 'logoBgActive'),
    transform: 'scale(0.8)',
  },
});

/* eslint-disable operator-linebreak */
/* * * * Haaretz  * * * * */
const TopAlef =
  'M 133 106.1 C 133 106.1 137.9 106.7 145 93.2 C 148.3 86.9 147 83 144.8 78.4 C 139.4 67.4 143.7 52.1 143.7 52.1 L 194.3 52.1 C 187.8 62.6 187.9 77 194.8 95.1 L 158.8 95.1 C 157.453 95.133 156.128 95.442 154.906 96.01 C 153.685 96.578 152.593 97.392 151.7 98.4 C 149.491 100.664 147.965 103.507 147.3 106.6 C 146.9 108.8 147.2 111.1 148.1 113.4 C 149 115.8 150.4 118.5 151.5 119.2 L 133 106.2 Z';
const MidAlef =
  'M 54.3 52.1 C 54 52.1 53.8 52.2 53.8 52.6 C 59.028 63.512 61.106 75.671 59.8 87.7 L 59 95.3 C 59 95.3 202.6 201.3 203 201.3 C 201.5 197.8 196.2 187.4 194.8 174.8 C 193.4 162.2 194.8 155.7 195.9 150.2 C 188.6 145.5 160.6 124.6 151.1 118 L 151.1 118 C 151.1 118 71.5 58 64.2 52.1 L 54.3 52.1';
const BottomAlef =
  'M 69.4 152.7 C 71 161.6 76 166.1 84.4 166.1 L 106.8 166.1 C 105.424 179.408 107.526 192.848 112.9 205.1 L 53.1 205.1 C 52.4 204.3 52.2 203.4 52.6 202.3 C 53.7 199.4 55.1 194 56.7 186.1 C 58.3 178.2 59.5 169.2 60.3 159 C 60.7 152.1 60.1 145.4 58.7 139 C 57.2 132.6 53.8 119.2 57.6 103.9 C 58.2 101.4 59.1 94.5 59.1 94.5 L 78.5 108.6 L 78.5 108.6 C 73.497 113.77 70.063 120.256 68.6 127.3 C 67.249 135.74 67.52 144.361 69.4 152.7';
const TopCloseMenuAlef =
  'M 41.298 77.692 C 41.298 74.848 41.298 72.005 41.298 69.161 C 41.298 66.318 41.298 63.474 41.298 60.631 C 41.298 57.787 41.298 54.944 41.298 52.1 L 224.702 52.1 C 224.702 60.631 224.702 69.161 224.702 77.692 L 201.776 77.692 C 194.135 77.692 186.493 77.692 178.851 77.692 C 171.209 77.692 163.567 77.692 155.925 77.692 C 148.284 77.692 140.642 77.692 133 77.692 C 125.358 77.692 117.716 77.692 110.075 77.692 C 102.433 77.692 94.791 77.692 87.149 77.692 L 64.224 77.692 Z';
const MidCloseMenuAlef =
  'M 41.298 140.796 C 51.487 140.796 61.676 140.796 71.865 140.796 C 82.054 140.796 92.244 140.796 102.433 140.796 L 133 140.796 C 143.189 140.796 153.378 140.796 163.567 140.796 C 173.756 140.796 183.946 140.796 194.135 140.796 C 204.324 140.796 214.513 140.796 224.702 140.796 C 224.702 132.265 224.702 123.735 224.702 115.204 L 133 115.204 C 102.433 115.204 71.865 115.204 41.298 115.204 L 41.298 140.796';
const BottomCloseMenuAlef =
  'M 41.298 205.1 C 71.865 205.1 102.433 205.1 133 205.1 L 224.702 205.1 C 224.702 196.569 224.702 188.039 224.702 179.508 L 206.362 179.508 C 200.248 179.508 194.135 179.508 188.021 179.508 C 181.908 179.508 175.794 179.508 169.681 179.508 C 163.567 179.508 157.454 179.508 151.34 179.508 C 145.227 179.508 139.113 179.508 133 179.508 C 126.887 179.508 120.773 179.508 114.66 179.508 C 108.546 179.508 102.433 179.508 96.319 179.508 L 77.979 179.508 L 59.638 179.508 C 53.525 179.508 47.411 179.508 41.298 179.508 C 41.298 188.039 41.298 196.569 41.298 205.1';

/* * * * The Marker * * * */
const TopMarker = 'M 80 205 L 36 205 L 36 51 L 80 51 L 80 205 Z';
const MidMarker = 'M 148 205 L 108 205 L 69 51 L 106 51 L 127 140 L 149 51 L 187 51 L 148 205 Z';
const BottomMarker = 'M 220 205 L 176 205 L 176 51 L 220 51 L 220 205 Z';
const TopCloseMenuMarker = 'M 36 77 L 36 51 L 220 51 L 220 77 L 36 77 Z';
const MidCloseMenuMarker =
  'M 220 141 L 36 141 L 36 115 L 79 115 L 126 115 L 170.667 115 L 220 115 L 220 141 Z';
const BottomCloseMenuMarker = 'M 36 205 L 36 179 L 220 179 L 220 205 L 36 205 Z';
/* eslint-enable operator-linebreak */

const paths = {
  htz: {
    TopLogo: TopAlef,
    MidLogo: MidAlef,
    BottomLogo: BottomAlef,
    TopClose: TopCloseMenuAlef,
    MidClose: MidCloseMenuAlef,
    BottomClose: BottomCloseMenuAlef,
    TopMenu: TopCloseMenuAlef,
    MidMenu: MidCloseMenuAlef,
    BottomMenu: BottomCloseMenuAlef,
  },
  tm: {
    TopLogo: TopMarker,
    MidLogo: MidMarker,
    BottomLogo: BottomMarker,
    TopClose: TopCloseMenuMarker,
    MidClose: MidCloseMenuMarker,
    BottomClose: BottomCloseMenuMarker,
    TopMenu: TopCloseMenuMarker,
    MidMenu: MidCloseMenuMarker,
    BottomMenu: BottomCloseMenuMarker,
  },
  hdc: {
    TopLogo: TopAlef,
    MidLogo: MidAlef,
    BottomLogo: BottomAlef,
    TopClose: TopCloseMenuAlef,
    MidClose: MidCloseMenuAlef,
    BottomClose: BottomCloseMenuAlef,
    TopMenu: TopCloseMenuAlef,
    MidMenu: MidCloseMenuAlef,
    BottomMenu: BottomCloseMenuAlef,
  },
};

type SVGSlimElement = {
  pauseAnimations: () => void,
  unpauseAnimations: () => void,
  setCurrentTime: number => void,
  beginElement: () => void,
};

function fireAnimation(
  svg: ?SVGSlimElement,
  top: ?SVGSlimElement,
  mid: ?SVGSlimElement,
  bottom: ?SVGSlimElement
): void {
  if (!svg || !top || !mid || !bottom) return;
  svg.pauseAnimations();
  svg.setCurrentTime(0);
  svg.unpauseAnimations();
  top.beginElement();
  mid.beginElement();
  bottom.beginElement();
}

MenuLogo.defaultProps = { requestedShape: 'Logo', };
function MenuLogo({ requestedShape, }: MenuLogoProps): Node {
  const { css, theme, } = useFela();
  const [ iconShape, setIconShape, ] = React.useState({
    prevShape: 'Logo',
    shape: requestedShape,
  });

  const { prevShape, shape, } = iconShape || {};
  const { logoType, } = theme;

  const svgRef = React.useRef(null);
  const topToLogoAnimate = React.useRef(null);
  const topToMenuAnimate = React.useRef(null);
  const midToLogoAnimate = React.useRef(null);
  const midToMenuAnimate = React.useRef(null);
  const bottomToLogoAnimate = React.useRef(null);
  const bottomToMenuAnimate = React.useRef(null);

  React.useEffect(() => {
    setIconShape(({ shape, }) => ({ prevShape: shape, shape: requestedShape, }));
  }, [ requestedShape, ]);

  React.useEffect(() => {
    if (prevShape === 'Logo' && [ 'Menu', 'Close', ].includes(shape)) {
      fireAnimation(
        svgRef.current,
        topToMenuAnimate.current,
        midToMenuAnimate.current,
        bottomToMenuAnimate.current
      );
    }
    else if (shape === 'Logo' && [ 'Menu', 'Close', ].includes(prevShape)) {
      fireAnimation(
        svgRef.current,
        topToLogoAnimate.current,
        midToLogoAnimate.current,
        bottomToLogoAnimate.current
      );
    }
  }, [ prevShape, shape, ]);

  return (
    <svg
      // $FlowFixMe
      ref={svgRef}
      className={css(svgStyles)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      aria-hidden
    >
      <path
        className={css({
          transitionDuration: duration,
          transitionTimingFunction: 'ease-out',
          transform: 'translateY(0) rotate(0)',
          transformOrigin: '130px 66px',
          ...(shape === 'Close'
            ? {
              transform: `translateY(${64 / 6}rem) rotate(-45deg)`,
            }
            : {}),
        })}
        d={paths[logoType].TopLogo}
      >
        <Animate
          type="Top"
          shape="Logo"
          prevShape="Menu"
          logoType={logoType}
          ref={topToLogoAnimate}
        />
        <Animate
          type="Top"
          shape="Menu"
          prevShape="Logo"
          logoType={logoType}
          ref={topToMenuAnimate}
        />
      </path>
      <path
        className={css({
          transitionDuration: duration,
          transitionTimingFunction: 'ease-out',
          opacity: 1,
          transform: 'scale(1)',
          transformOrigin: '200px 140px',
          ...(shape === 'Close'
            ? {
              opacity: 0,
              transform: 'scale(0)',
            }
            : {}),
        })}
        d={paths[logoType].MidLogo}
      >
        <Animate
          type="Mid"
          shape="Logo"
          prevShape="Menu"
          logoType={logoType}
          ref={midToLogoAnimate}
        />
        <Animate
          type="Mid"
          shape="Menu"
          prevShape="Logo"
          logoType={logoType}
          ref={midToMenuAnimate}
        />
      </path>
      <path
        className={css({
          transitionDuration: duration,
          transitionTimingFunction: 'ease-out',
          transformOrigin: '130px 190px',
          transform: 'translateY(0) rotate(0)',
          ...(shape === 'Close'
            ? {
              transform: `translateY(-${64 / 6}rem) rotate(45deg)`,
            }
            : {}),
        })}
        d={paths[logoType].BottomLogo}
      >
        <Animate
          type="Bottom"
          shape="Logo"
          prevShape="Menu"
          logoType={logoType}
          ref={bottomToLogoAnimate}
        />
        <Animate
          type="Bottom"
          shape="Menu"
          prevShape="Logo"
          logoType={logoType}
          ref={bottomToMenuAnimate}
        />
      </path>
    </svg>
  );
}

type AnimateProps = {
  shape: string,
  prevShape: string,
  type: string,
  logoType: 'htz' | 'tm' | 'hdc',
};
// $FlowFixMe
const Animate = React.forwardRef(({ shape, prevShape, type, logoType, }: AnimateProps, ref) => (
  <animate
    attributeName="d"
    restart="whenNotActive"
    fill="freeze"
    begin="indefinite"
    dur={duration}
    from={paths[logoType][`${type}${prevShape}`]}
    to={paths[logoType][`${type}${shape}`]}
    ref={ref}
  />
));

export default MenuLogo;
