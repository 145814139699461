export const dropdownItemStyle = theme => ({
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  borderBottomColor: theme.color('masthead', 'menuItemBorder'),
  display: 'flex',
  ':last-child': {
    borderBottomStyle: 'none',
  },
  extend: [ theme.type(-2), ],
});

export const dropdownListStyle = theme => ({
  backgroundColor: theme.color('masthead', 'bgMenu'),
  color: theme.color('neutral', '-10'),
  display: 'inline-block',
  fontWeight: '700',
  minHeight: '100%',
  minWidth: '100%',
  width: 'fit-content',
  start: 0,
  // ...(theme.direction === 'rtl' ? { right: 0, } : { left: 0, }),
  position: 'absolute',
  zIndex: '100',
  top: '100%',
  '& *': {
    ':focus': {
      outline: 'none',
    },
  },
});
