// @flow
import { useQuery, } from 'react-apollo';
import { AUTHORS_QUERY, } from '@haaretz/graphql';
import useArticleId from './useArticleId';
import useBlogSectionData from './useBlogSectionData';
import useLiveBlogQueryVariables from './useLiveBlogQueryVariables';

export default function useAuthorsData() {
  const articleId = useArticleId();

  const queryVariables = useLiveBlogQueryVariables(articleId);

  const { data, loading, error, } = useQuery(AUTHORS_QUERY, {
    fetchPolicy: 'cache-first',
    variables: queryVariables,
    skip: !articleId,
  });

  const blogSection = useBlogSectionData();

  if (loading) return null;
  if (error) {
    console.error('Tags error', error);
    return null;
  }

  if (!data) return null;

  const authors = data?.Page?.slots?.article?.authors;

  if (
    !(authors && authors?.length && authors?.[0]?.biography)
     && !(Array.isArray(authors) && authors?.length && authors[0]?.contentName)
    && blogSection
  ) {
    return [ blogSection, ];
  }

  return authors;
}
