// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import MenuList from './MobileNavigationMenuList';
import MenuHeader from './MobileMenuHeader';
import A11yDialog from '../A11yDialog/A11yDialog';
import VisuallyHidden from '../VisuallyHidden/VisuallyHidden';
import type { MenuItemType, MainMenuType, } from './MenuItemType';
import checkSiteFromConfig from '../../utils/checkSiteFromConfig';

const { isHDC, } = checkSiteFromConfig();

type htmlRefType = { current: null | HTMLElement, };

type MobileNavigationMenuProps = {
  menu: MainMenuType,
  suggestedSections: ?(MenuItemType[]),
  isHomepage: boolean,
  isLoggedIn: boolean,
  user: { type: ?string, firstName: ?string, id: ?string, university: boolean, },
  withPromotion: boolean,
  menuIsOpen: boolean,
  menuRef: { current: null | HTMLDivElement, },
  navRefs: [htmlRefType, htmlRefType, ],
  menuIsClosing: boolean,
  closingFunc: () => any,
  onSearchToggle: boolean => void,
  onCloseRef: { current: null | (() => void), },
  Search: React.ElementType,
  hideSearch: boolean,
  biAction: ?({ actionCode: number, }) => void,
};

type NavigationCoreProps = MobileNavigationMenuProps & {
  theme: Object,
  css: (Object | (Object => Object)) => string,
};

const menuStyles = ({ theme, menuIsClosing, styleAsOpen, withPromotion, }) => ({
  fontFamily: theme.fontStacks[theme.navMenuStyle.mobile.menuFontStack],
  display: 'grid',
  height: '100%',
  width: '100%',
  position: 'relative',
  gridTemplateRows: '14rem 8vw 1fr 9rem',
  gridTemplateColumns: '0 1fr 0',
  gridColumnGap: '2rem',
  willChange: 'transform',
  backgroundColor: theme.color('white'),
  transitionDuration: '0.6s',
  transform: 'translateY(110%)',
  ...(styleAsOpen && !menuIsClosing
    ? {
      transform: 'translateY(0)',
    }
    : {}),
  // the skewed element is in an inner div and a very thin white line is still visible
  // at its top from the parents' background. This ':before' covers that line.
  // (skewed element is not placed on this element because its overflow has to be visible)
  ':before': {
    content: '""',
    backgroundColor: withPromotion
      ? theme.color('mobileNav', 'promotionBg')
      : theme.color('mobileNav', 'skewedBg'),
    width: '100%',
    height: '2px',
    position: 'absolute',
    top: '0',
    end: '0',
  },
});

const skewedBackgroundStyle = ({ theme, withPromotion, }) => ({
  gridArea: '1 / 1 / 3 / 4',
  overflow: 'hidden',
  zIndex: 1,
  // this next rule ensures that the z-index property would not be ignored in ios
  position: 'relative',
  pointerEvents: 'none',
  ':after': {
    content: '""',
    display: 'block',
    width: '100%',
    height: '100%',
    backgroundColor: withPromotion
      ? theme.color('mobileNav', 'promotionBg')
      : theme.color('mobileNav', 'skewedBg'),
    transformOrigin: `bottom ${theme.direction === 'ltr' ? 'right' : 'left'}`,
    transform: `skewY(${theme.direction === 'ltr' ? '' : '-'}4deg) translateY(-1px)`,
    // backgroundImage: withPromotion ? 'linear-gradient(91deg, #f4d835, #fff169)' : undefined,
    backgroundImage: withPromotion ? `linear-gradient(91deg,${theme.color('mobileNav', 'gradientStart')}, ${theme.color('mobileNav', 'gradientEnd')})` : undefined,
  },
});

function NavigationCore({
  isHomepage,
  isLoggedIn,
  user,
  withPromotion,
  closingFunc,
  menuIsOpen,
  menuIsClosing,
  menuRef,
  navRefs,
  onCloseRef,
  onSearchToggle,
  Search,
  hideSearch,
  menu,
  suggestedSections,
  biAction,
  css,
  theme,
}: NavigationCoreProps): React.Node {
  const { subscriptionGiftUrl, subscriptionGiftText, } = theme.mastheadSubscriptionI18n;
  const giftPromotions = [];
  if (isHDC && user?.type === 'paying') {
    giftPromotions.push({
      name: subscriptionGiftText,
      url: subscriptionGiftUrl,
      isGift: true,
      __typename: 'NavigationLink',
      key: 'gift',
      inputTemplate: 'NavigationLink',
    });
  }
  return (
    <A11yDialog
      appendTo="modalsRoot"
      elementToHide="pageRoot"
      closingFunc={closingFunc}
      closeOnOutsideClick
      isVisible={menuIsOpen}
      containerMiscStyles={{
        position: 'fixed',
        zIndex: theme.getZIndex([ 'masthead', 'mobile', ], 1),
        height: '85%',
        width: '100%',
        top: 'unset',
        left: 'unset',
        bottom: '0',
        outline: 'none',
        ':-moz-focusring': { outline: 'none', },
        transform: 'none',
      }}
      overlayBgColor="rgba(0,0,0,0.56)"
      overlayMiscStyles={{
        position: 'fixed',
        zIndex: theme.getZIndex([ 'masthead', 'mobile', ]),
        transition: 'opacity 0.4s',
        opacity: 1,
        animationDuration: '0.4s',
        animationName: {
          '0%': { opacity: 0, },
          '100%': { opacity: 1, },
        },
        ...(menuIsClosing
          ? {
            opacity: 0,
          }
          : {}),
      }}
      render={() => (
        <div className={css(menuStyles)} ref={menuRef} data-test="menuMobile">
          <div className={css(skewedBackgroundStyle)} aria-hidden="true" />
          <MenuHeader
            isLoggedIn={isLoggedIn}
            user={user}
            Search={Search}
            hideSearch={hideSearch}
            onSearchToggle={onSearchToggle}
            biAction={biAction}
            css={css}
            theme={theme}
            promotion={menu.promotions && menu.promotions[0]}
            withPromotion={withPromotion}
          />
          <MenuList
            menu={menu}
            giftPromotions={giftPromotions}
            isLoggedIn={isLoggedIn}
            showFirstPromotion={user.type !== 'paying'}
            suggestedSections={suggestedSections}
            isHomepage={isHomepage}
            isOpen={menuIsOpen}
            navRefs={navRefs}
            onCloseRef={onCloseRef}
            biAction={biAction}
            css={css}
            theme={theme}
          />
          <button type="button" onClick={closingFunc}>
            <VisuallyHidden>{theme.mobileNavigationMenuI18n.close}</VisuallyHidden>
          </button>
        </div>
      )}
    />
  );
}

export default function MobileNavigationMenu({
  menu,
  suggestedSections,
  user,
  isLoggedIn,
  isHomepage,
  withPromotion,
  menuIsOpen,
  closingFunc,
  menuRef,
  navRefs,
  onCloseRef,
  onSearchToggle,
  menuIsClosing,
  Search,
  hideSearch,
  biAction,
}: MobileNavigationMenuProps): React.Node {
  const [ styleAsOpen, setStyleAsOpen, ] = React.useState(false);
  const { css, theme, } = useFela({
    menuIsOpen,
    menuIsClosing,
    styleAsOpen,
    withPromotion,
  });


  React.useEffect(() => {
    // The extra styleAsOpen is set for a more consistent transition experience in ios :/
    if (menuIsOpen) {
      const id = setTimeout(setStyleAsOpen, 50, true);
      return () => {
        clearTimeout(id);
      };
    }
    setStyleAsOpen(false);
    return undefined;
  }, [ menuIsOpen, ]);

  return (
    <NavigationCore
      menu={menu}
      suggestedSections={suggestedSections}
      isHomepage={isHomepage}
      isLoggedIn={isLoggedIn}
      user={user}
      withPromotion={withPromotion}
      closingFunc={closingFunc}
      menuIsOpen={menuIsOpen}
      menuIsClosing={menuIsClosing}
      menuRef={menuRef}
      navRefs={navRefs}
      onCloseRef={onCloseRef}
      onSearchToggle={onSearchToggle}
      Search={Search}
      hideSearch={hideSearch}
      biAction={biAction}
      css={css}
      theme={theme}
    />
  );
}
